import { Button } from '@mui/material';
import {FC} from 'react';
import styles from './CalculationProgress.module.scss';

export interface CalculationProgressProps {
    onCancel: () => void
}

const CalculationProgress: FC<CalculationProgressProps> = (props) => {
    return (
        <div className={styles.root}>
            <img src="/images/prpgress.image.svg"/>
            The calculation is in progress...
            <Button variant="contained" color="primary" onClick={props.onCancel}>Cancel</Button>
        </div>
    );
}

export default CalculationProgress;
