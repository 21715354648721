import { FC } from 'react';
import Menu from '@mui/material/Menu';
import "./DropDownMenu.scss";

export interface DropDownMenuProps {
    id: string,
    open: boolean,
    anchorEl: HTMLElement | null,
    handleClose: () => void
}

const DropDownMenu: FC<DropDownMenuProps> = (props) => {

    return (
        <Menu
            id={props.id}
            className='DropDownMenu'
            anchorEl={props.anchorEl}
            open={props.open}
            onClose={props.handleClose}
            disableAutoFocusItem={true}
            MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
            }}
            PaperProps={{  
                style: {  
                    width: '112px',
                },  
            }}>
            {props.children}
        </Menu>
    );
}

export default DropDownMenu;
