import AccountMyDetails from "./pages/AccountMyDetails/AccountMyDetails";
import Billing from "./pages/Billing/Billing";
import PlansAndPricing from "./pages/PlansAndPricing/PlansAndPricing";
import PrivacyAndSecurity from "./pages/PrivacyAndSecurity/PrivacyAndSecurity";
import Users from "./pages/Users/Users";

const ACCOUNT_ROUTE = [{
    label: 'My details',
    tab: 'details',
    component: AccountMyDetails
}, {
    label: 'Privacy and Security',
    tab: 'privacy-security',
    component: PrivacyAndSecurity
}, {
    label: 'Plans & Pricing',
    tab: 'plans-pricing',
    component: PlansAndPricing
}, /*{
    label: 'Billing',
    tab: 'billing',
    component: Billing
},*/ {
    label: 'Users',
    tab: 'users',
    component: Users
}];

export default ACCOUNT_ROUTE;