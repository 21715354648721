import { Box } from "@mui/material";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    onChange, 
    onTypeChange, 
    removeInput, 
    removeState as removeStateAction, 
    selectorSelectedInputs, 
    toReadable 
} from "../../store/statesStore";
import { RootState } from "../../store/store";

interface DecompositionStateProps {
    state: any,
    inputIndex: any, 
    index: any,
    isMaxDisabled: any
};

const DecompositionState: FC<DecompositionStateProps> = (props) => {

    const dispatch = useDispatch();

	const isAutomatic = useSelector((state: RootState) => state.statesStore.isAutomatic);

    const selectedColumns = useSelector((state: RootState) => selectorSelectedInputs(state));

    const removeState = (inputIndex: any, stateIndex: any) => {
        if ((selectedColumns[inputIndex] as any).states.length < 2) {
            dispatch(removeInput({index: inputIndex}));
        } else {
            dispatch(removeStateAction({
                inputIndex,
                stateIndex
            }))
        }
    }

    return (
        <Box className="decompContainerContent">
            {
                isAutomatic ?
                <Box>{ props.state.type }</Box> :
                <Box >
                    <input 
                        value={ props.state.type } 
                        onChange={(event) => dispatch(onTypeChange({
                            inputIndex: props.inputIndex,
                            index: props.index,
                            value: event.target.value
                        }))} />
                </Box>  
            }
            <Box className="inputContainer">
                <input 
                    type="number" 
                    value={toReadable(props.state.min)}
                    disabled={true}/>
            </Box>
            <Box className="inputContainer">
                <input 
                    type="number" 
                    value={toReadable(props.state.max)}
                    disabled={props.isMaxDisabled || isAutomatic}
                    onChange={(event) => dispatch(onChange({
                        inputIndex: props.inputIndex,
                        index: props.index,
                        value: event.target.value
                    }))}/>
            </Box>
            {
                !isAutomatic &&
                <button className="button" onClick={() => removeState(
                    props.inputIndex,
                    props.index
                )}>-</button >
            }
        </Box>
    )
}

export default DecompositionState;
