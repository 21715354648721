import React, {FC} from 'react';
import {ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {ChartType} from "../../libs/const/chartType.const";
import './ChartViewToggle.scss';

interface ChartViewToggleProps {
    value: ChartType,
    disabled: boolean,
    onChange: (event: any, chart: ChartType)=>void
}

const ChartViewToggle: FC<ChartViewToggleProps> = (props) => {
    function TooltipToggleButton(tooltipProps:any) {
        const { title, ...other } = tooltipProps;
        return (
            <Tooltip title={title} arrow>
                <ToggleButton {...other}/>
            </Tooltip>
        );
    }
    
    return (
        <ToggleButtonGroup className={"ChartViewToggle"} value={props.value} onChange={props.onChange} exclusive
                           disabled={props.disabled}>
            <TooltipToggleButton value={ChartType.HISTOGRAM} title="Histogram">
                <img src="/images/histogram.icon.svg" className={props.value === ChartType.HISTOGRAM ? "white" : "gray"}/>
            </TooltipToggleButton>
            <TooltipToggleButton value={ChartType.BOXPLOTS} title="Box plots">
                <img src="/images/boxplots.icon.svg" className={props.value === ChartType.BOXPLOTS ? "white" : "gray"}/>
            </TooltipToggleButton>
        </ToggleButtonGroup>
    );
}

export default ChartViewToggle;
