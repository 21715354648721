import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import styles from './PageLayout.module.scss';
import LinkItem from '../../components/LinkItem/LinkItem';
import {menus} from '../../libs/const/menu.const';
import SignOut from "../../components/SignOut/SignOut";
import LimitationCard from '../../components/LimitationCard/LimitationCard';
import { LicensingData } from '../../libs/models/licensingData.model';
import { CurrentUser } from '../../libs/observables/CurrentUser';

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  height: "auto",
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  height: "auto",
  width: `88px`,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        padding: '28px 10px',
        width: '259px',
        maxWidth: '259px',
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme), 
        width: '68px',
        padding: '28px 10px',
      },
    }),
    '& .MuiDrawer-root': {
      position: 'absolute'
    },
  }),
);

export const  PageLayout: React.FC<{}> = (props) => {
    const [open, setOpen] = React.useState(true);
    const [licensingInfo, setLicensingInfo] = React.useState<LicensingData>();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        const subscription = CurrentUser.currentUser$.subscribe((value) => {
          if (value && value.licensingInfo) {
            setLicensingInfo(value.licensingInfo);
          }
        });
        return () => subscription.unsubscribe();
    });

    return (
      <Box className={styles.main}>
          <Drawer className={styles.drawer} PaperProps={{
            style: {
              position: "inherit",
              flex: "1",
              justifyContent: "space-between",
              borderRight: "none"
            }
          }} variant="permanent" open={open}>
          <Box>
            <img className={styles.logo} alt="" src="/images/logo.svg" onClick={open ? handleDrawerClose : handleDrawerOpen}/>
            <Box className={styles.menu}>
              {
                menus.map(itemMenu => 
                  <LinkItem
                    title={!open ? itemMenu.description : ''}
                    key={itemMenu.href}
                    showDescription={open} 
                    href={itemMenu.href} 
                    iconPath={itemMenu.iconPath}>
                    {itemMenu.description}
                  </LinkItem>
                )
              }
            </Box>
          </Box>
          <Box>
            <LimitationCard open={open} licensingInfo={licensingInfo}/> 
            <Box className={styles.logoArea}>
              <img src="https://simdecstorage.blob.core.windows.net/login-page/LUT_University_Logo.svg"/>
              <img src="https://simdecstorage.blob.core.windows.net/login-page/businessFinland.png"/>
            </Box>
          </Box>
          <Box style={{
            marginTop: '5px'
          }}>
            <SignOut showDescription={open} />
          </Box>
          </Drawer>
          <Box component="main" className={styles.content}>
          {props.children}
        </Box>
      </Box>
    );
  }

