import React, { FC } from "react";
import { BehaviorSubject } from "rxjs";

export enum ALETR_TYLE {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    INFO = 'INFO'
}

export const ALERT_TYPE_TO_CLASS = {
    [ALETR_TYLE.ERROR]: 'alertError',
    [ALETR_TYLE.WARNING]: 'alertWarning',
    [ALETR_TYLE.INFO]: 'alertInfo',
}

export interface AlertSettings {
    title?: string,   
    children?: FC<any> | string 
    cancelBtnTitle?: string,
    actionBtnTitle?: string,
    onClose?: () => void,
    onCancel?: () => void,
    onAction?: () => void
    alertType?: ALETR_TYLE,
    isAlert?: boolean,
}

export const alertSubject: BehaviorSubject<AlertSettings> = new BehaviorSubject({} as AlertSettings);

export const displayAlertSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

export const displayProgressSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

export const showProgress = () => {
    displayProgressSubject.next(true);
}

export const hideProgress = () => {
    displayProgressSubject.next(false);
}

export const showAlert = (settings: AlertSettings) => {
    alertSubject.next(settings);
    displayAlertSubject.next(true);
}

export const hideAlert = () => {
    displayAlertSubject.next(false);
}

export const showErrorAlert = (settings: AlertSettings) => {
    showAlert({
        ...settings,
        alertType: ALETR_TYLE.ERROR
    });
}

export const showWarningAlert = (settings: AlertSettings) => {
    showAlert({
        ...settings,
        alertType: ALETR_TYLE.WARNING
    });
}

export const showInfoAlert = (settings: AlertSettings) => {
    showAlert({
        ...settings,
        alertType: ALETR_TYLE.INFO
    });
}
