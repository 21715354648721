import { configureStore } from '@reduxjs/toolkit';

import chartReducer from './chartStore';
import colorReducer from './colorStore';
import dataReducer from './dataStore';
import statesReducer from './statesStore';
// ...

const store = configureStore({
  reducer: {
    chartStore: chartReducer,
    colorStore: colorReducer,
    dataStore: dataReducer,
    statesStore: statesReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;