import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

const visibleCountMinDefault = 50;
const visibleCountMaxDefault = 80;

export const useChartSlice = createSlice({
    name: 'chart',
    initialState:{
        min: 0,
        max: 0,
        step: 0,
        precission: 0,
        visibleCount: 0,
        start: 0,
        visiblePercent: 0,
        values: [],
        colors: []
    },
    reducers: {
        init: (state, action) => {
            state.start = 0;
            state.min = action.payload.min;
            state.max = action.payload.max;
            state.step = action.payload.step;
            state.precission = action.payload.precission;
            state.values = action.payload.values;
            state.visibleCount = (action.payload.max - action.payload.min) / action.payload.step;
            state.colors = action.payload.colors;
        },
        zoomIn: (state) => {
            var tmp = Math.ceil(state.visibleCount / 2);

            state.visibleCount = Math.max(tmp, visibleCountMinDefault);
        },
        zoomOut: (state) => {
            var tmp = state.visibleCount * 2;

            state.visibleCount = Math.min(tmp, (state.values[0] as any).length);

            state.start = Math.min(state.start, (state.values[0] as any).length - state.visibleCount);
        },
        left: (state) => {
            var tmp = state.start - Math.ceil(state.visibleCount / 2);

            state.start = Math.max(tmp, 0);
        },
        right: (state) => {
            var tmp = state.start + Math.ceil(state.visibleCount / 2);

            state.start = Math.min(tmp, (state.values[0] as any).length - state.visibleCount);
        },
        visiblePercent: (state, action) => {
          state.visiblePercent = action.payload.visiblePercent;
        },
        setColors: (state, action) => {
          state.colors = action.payload.colors;
        }
    }
});

export const { 
  init, 
  zoomIn, 
  zoomOut, 
  left, 
  right, 
  visiblePercent,
  setColors
} = useChartSlice.actions;

export default useChartSlice.reducer;

export const chartDataWithVisiblePercent = (state: RootState) => {
    const chartStore = state.chartStore;
    if (chartStore.visibleCount === 0) { return {}; }

    var showCount = visibleCountMinDefault;
    var min = 1;

    for (var i = showCount; i < visibleCountMaxDefault; i++) {
        var tmp = chartStore.visibleCount / i;
        if (tmp === 0) {
            showCount = i;
            break;
        }

        var tmpInt = Math.ceil(tmp);
        var tmpMin = Math.min(tmpInt - tmp, tmp + 1 - tmpInt);
        if (min > tmpMin) {
            min = tmpMin;
            showCount = i;
        }
    }
    const step = Math.round(chartStore.visibleCount / showCount);
    showCount = step > 0 ? Math.ceil(chartStore.visibleCount / step) : 0;
    let labels = new Array(showCount);
    let datasets = new Array();

    if (chartStore.values) {
      for (i = 0; i < chartStore.values.length; i++) {
          datasets[i] =
          {
              label: "sc_" + (i + 1),
              backgroundColor: chartStore?.colors?.[i],
              data: new Array(showCount).fill(0)
          };
      }
    }

    let ind = 0;
    let precission = 0;

    for (i = 0; ; i++) {
        var labelStep = chartStore.step * step * Math.pow(10, precission) * 6; // 6 bins between labels

        if (labelStep < 1) {
            precission++;
        }
        else if (labelStep >= 10) {
            precission--;
        }
        else {
            break;
        }
    }
    //precission++;
    
    let visiblePercent = 0;
    for (i = 0; i < chartStore.visibleCount; i++) {

        if ((i % step) === 0) {
            ind = i / step;

            //var tmpP = precission + ((ind % 6) == 0 ? 0 : 1);

            labels[ind] = +(Math.round((((chartStore.min + (chartStore.start + i) * chartStore.step) + ("e" + precission)) as any)) + ("e" + (precission * -1)));
        }
        if (chartStore.values) {
          for (var j = 0; j < chartStore.values.length; j++) {
              datasets[j].data[ind] += chartStore.values[j][chartStore.start + i];
              visiblePercent += chartStore.values[j][chartStore.start + i];
          }
        }
    }    

    return {
        labels: labels,
        datasets: datasets,
        visiblePercent
    }
}

export const visiblePercentSelect = (state: RootState) => 
  chartDataWithVisiblePercent(state).visiblePercent;

export const selectChartData = (state: RootState) => {
  const data = chartDataWithVisiblePercent(state);
  return {
    labels: data.labels,
    datasets: data.datasets,
  }
}

export const selectChartOptions = (state: RootState) => {
  const data = chartDataWithVisiblePercent(state);
  let maxY = 0;
  if (data.datasets) {
    for (let i = 0; i <(data.datasets as any)[0].data.length; i++) {
      let itemMaxY = data.datasets?.reduce((sum, item) => {
        sum += item;
        return item;
      }, 0);
      if (maxY < itemMaxY) {
        maxY = itemMaxY;
      }
    }
  }

  return {
    plugins: {
      title: {
        display: true,
        text: `Distribution of the output (0%)`
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          autoSkip: true,
          autoSkipPadding: 16
        },
        title: {
          display: true,
          text: "Output",
          font: {
            size: 15
          }
        },
        grid: {
          offset: false                                
        }
      },
      y: {
        title: {
          display: true,
          text: "Probability",
          font: {
            size: 15
          }
        },
        stacked: true,
        ticks: {
          min: 0,
          max: maxY, 
          callback: function (value: any) {
            return  (value / this.max * 100).toFixed(0) + "%";
          }
        },
        grid: {
          drawBorder: false
        }
      }
    }
  }
}
