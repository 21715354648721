import LinearProgressWithLabel from '../LinearProgressWithLabel/LinearProgressWithLabel';
import {FC} from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styles from './FileProgressUpload.module.scss';
import { formatBytes } from '../../libs/services/bytes.service';
export interface FileProgressUploadProps {
    fileName: string,
    fileSize: number;
    progress: number;
}

const FileProgressUpload: FC<FileProgressUploadProps> = (props) => {
    return (
        <div className={styles.progressUploadCompoent}>
           Income statement {props.fileName} {props.progress === 100 && <CheckCircleIcon className={styles.icon}/>}
           <div className={styles.fileSize}>{formatBytes(props.fileSize)}</div>
           <LinearProgressWithLabel value={props.progress}/>
        </div>
    );
}

export default FileProgressUpload;
