import React from "react";
import UploadFileComponent from "../../components/UploadFileComponent/UploadFileComponent";
import { checkFileExtension, exelFileExtensionList } from '../../libs/services/fileExtension.service';
import style from "./Home.module.scss"
import FileProgressUpload from "../../components/FileProgressUpload/FileProgressUpload";
import {startSession} from "../../libs/services/session.service";
import {upload} from "../../libs/services/fileUpload.service";
import {AxiosProgressEvent} from "axios";
import {Box} from "@mui/material";
import { Redirect } from "react-router-dom";
import { showErrorAlert } from "../../account/components/alerts/alert.service";
import { UserData } from "../../libs/models/userData.model";
import { 
    getErrorMessages, 
    isLimitationErrorByUser 
} from "../../libs/utils/Limitation";
import { CurrentUser } from "../../libs/observables/CurrentUser";
import { Subscription } from "rxjs";

interface HomeState {
    fileName: string,
    fileSize: number,
    uploadProgress: number | null,
    sessionId: string,
    currentUser?: UserData
}

const HomeStateBeginValue = {
    fileName: "",
    fileSize: 0,
    uploadProgress: null,
    sessionId: ''
}

export class Home extends React.Component<any, HomeState> {

    constructor(props: any) {
        super(props);
        this.state = HomeStateBeginValue;
        this.progressCallback = this.progressCallback.bind(this);
        this.abortRequest = this.abortRequest.bind(this);
        this.onFileAdd = this.onFileAdd.bind(this);
    }
    
    componentWillUnmount() {
        this.user$?.unsubscribe();
    }
    
    componentDidMount() {
        this.user$ = CurrentUser.currentUser$.subscribe((currentUser: UserData) => {
            this.setState({currentUser});
        });
    }

    public user$: Subscription | undefined;

    progressCallback(progressEvent:AxiosProgressEvent) {
        if(progressEvent.progress === 1) {
            this.setState({
                uploadProgress: progressEvent.progress
            });
        } else {
            this.setState({
                uploadProgress: progressEvent.progress || 0
            });
        }
    }

    abortController: AbortController = new AbortController();

    abortRequest () {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }
    }
    
    async onFileAdd(file: File) {
        if(checkFileExtension(file, exelFileExtensionList)) {        
            this.setState({
                fileName: file.name,
                fileSize: file.size
            });            
            try {
                this.setState({
                    uploadProgress: 0
                });
                let sessionId = await startSession({
                    signal: this.abortController.signal
                });
                
                await upload(sessionId.data, file, {
                    progressCallback: this.progressCallback,
                    abortController: this.abortController
                });

                this.setState({
                    sessionId: sessionId.data
                });
            } catch (err) {
                this.setState(HomeStateBeginValue);
            }
        } else {
            showErrorAlert({
                title: 'Error',
                children: 'File with this extension is not supported',
                isAlert: true
            });
        }
    }
    
    render() {
        return (
            <>
                { 
                    this.state.sessionId ?
                    <Redirect to={`/analysis/${this.state.sessionId}`}/> :
                    <div className={style.root}>
                        <Box className={style.fileArea}>
                            <div className={style.title}>Upload files</div>
                            <div className={style.uploadWripper}>
                                {
                                    this.state.uploadProgress != null ?
                                        <FileProgressUpload 
                                            fileName={this.state.fileName} 
                                            fileSize={this.state.fileSize} 
                                            progress={this.state.uploadProgress * 100}/> :
                                        <UploadFileComponent 
                                            disabled={
                                                this.state.currentUser ? 
                                                    isLimitationErrorByUser(this.state.currentUser): 
                                                    true
                                            }
                                            title={
                                                this.state.currentUser ? 
                                                `${getErrorMessages(this.state.currentUser)}`:``
                                            }
                                            onFileAdd={this.onFileAdd}>
                                            {exelFileExtensionList.join(', ')}
                                        </UploadFileComponent>
                                            
                                }
                            </div>
                        </Box>
                        <div className={style.notificationAreaWrapper}>
                            <div className={style.notificationArea}>
                                <div className={style.notificationItem}>
                                    <img src="https://simdecstorage.blob.core.windows.net/login-page/warning.svg"/>
                                    Your data should be of size N+1 observations x K variables
                                </div>
                                <div className={style.notificationItem}>
                                    <img src="https://simdecstorage.blob.core.windows.net/login-page/warning.svg"/>
                                    The first variable should be the output which you want to analyze 
                                </div>
                                <div className={style.notificationItem}>
                                    <img src="https://simdecstorage.blob.core.windows.net/login-page/warning.svg"/>
                                    Only csv files comma delimited
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
                
        );
    }
}
