import React, { FC } from 'react';
import styles from './UserInfo.module.scss';
import {Avatar} from "@mui/material";
import {UserData} from "../../libs/models/userData.model";

interface UserInfoProps {
    showDescription?: boolean,
    user?: UserData,
    small: boolean
}

const UserInfo: FC<UserInfoProps> = (props) => {
    let displayName = "";
    
    if (props.user) {
        if (props.user.givenName) {
            displayName = props.user.givenName;
        }
        if (props.user.surname) {
            displayName = `${displayName} ${props.user.surname}`;
        }
    }
    
    return (
        <div className={styles.UserInfo}>
            <Avatar src={props.user?.photoUrl} className={props.small ? styles.avatarSmall : styles.avatar}/>
            {
                props.showDescription &&
                <div className={styles.userDescription}>
                <span title={displayName} className={`${props.small ? styles.nameSmall : styles.name} ${styles.cutText}`}>{displayName}</span><br/>
                <span title={props.user?.email} className={`${props.small ? styles.mailSmall : styles.mail} ${styles.cutText}`}>{props.user?.email}</span>
                </div>

            }
        </div>);
};

export default UserInfo;
