import {Box, Checkbox, Divider, FormControlLabel, LinearProgress} from "@mui/material";
import React from "react";
import ChipListComponent from "../../components/ChipListComponent/ChipListComponent";
import styles from "./Results.module.scss";
import {FileInfo} from "../../libs/const/fileInfo.const";
import {deleteSession, getSession} from "../../libs/services/session.service";
import {AxiosError} from "axios";
import { ChartType } from "../../libs/const/chartType.const";
import { calculate, CalculatePyload, getResult } from "../../libs/services/calculate.service";
import CalculationProgress from "../../components/CalculationProgress/CalculationProgress";
import { Redirect } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CropDinIcon from '@mui/icons-material/CropDin';
import GridViewIcon from '@mui/icons-material/GridView';
import { Provider } from "react-redux";
import { Subscription } from 'rxjs';
import store from '../../charts/store/store';
import Charts from "../../charts/Charts/Charts";
import ChartViewToggle from "../../components/ChartViewToggle/ChartViewToggle";
import { showErrorAlert } from "../../account/components/alerts/alert.service";
import { UserData } from "../../libs/models/userData.model";
import { CurrentUser } from "../../libs/observables/CurrentUser";
import { isLimitationErrorByUser } from "../../libs/utils/Limitation";

export enum GridType {
    COLOMN,
    ROW
}

export interface ResultsState {
    session: FileInfo|null,
    displayChartList: Array<ChartType>,
    fields: Array<string>,
    isHeadersRowPresented: boolean,
    isCalculation: boolean,
    loading: boolean,
    calculationResult: any,
    resultId: string | null,
    toList: boolean,
    gridType: GridType,
    mainChart: ChartType,
    decomposition: any,
    currentUser?: UserData
}

const ResultsStateDefault = {
    session: null,
    displayChartList: [],
    fields: [],
    isHeadersRowPresented: true,
    isCalculation: false,
    calculationResult: null,
    resultId: null,
    toList: false,
    loading: false,
    gridType: GridType.COLOMN,
    mainChart: ChartType.HISTOGRAM,
    decomposition: null
}

export default class Results extends React.Component<any, ResultsState> {

    constructor(props: any) {        
        super(props);
        this.state = ResultsStateDefault;
        this.changeChartVisualization = this.changeChartVisualization.bind(this);
        this.onFieldsChange = this.onFieldsChange.bind(this);
        this.onSkipHeadersRowClick = this.onSkipHeadersRowClick.bind(this);
        this.abortRequest = this.abortRequest.bind(this);
        this.calculate = this.calculate.bind(this);
        this.delete = this.delete.bind(this);
        this.setGridType  = this.setGridType.bind(this);
        this.setMainChart  = this.setMainChart.bind(this);
        this.onCalculate = this.onCalculate.bind(this);
        this.onRecalculate = this.onRecalculate.bind(this);
        this.user$ = CurrentUser.currentUser$.subscribe(currentUser => this.setState({currentUser}))
    }

    componentWillUnmount() {
        this.user$.unsubscribe();
    }

    public user$: Subscription;

    abortController: AbortController = new AbortController();

    onFieldsChange(fields:string[]) {
        this.setState({
            fields
        })
    }

    onSkipHeadersRowClick(event: any){
        this.setState({
            isHeadersRowPresented: !this.state.isHeadersRowPresented,
        });
    };

    componentDidMount() {
        this.loadSession();
    }

    setGridType(gridType: GridType) {
        this.setState({
            gridType
        })
    }

    setMainChart(event: any, mainChart: ChartType) {
        this.setState({
            mainChart
        })
    }

    changeChartVisualization(chartType: ChartType) {
        let displayChartList = [...this.state.displayChartList]
        displayChartList.includes(chartType) ?
            displayChartList = displayChartList.filter(item => item !== chartType):
            displayChartList.push(chartType);
        this.setState({displayChartList});
    }
    
    loadSession(){
        const sessionId = this.props.match.params.sessionId;
        this.setState({
            loading: true
        });
        getSession(sessionId)
            .then((response) => {
                let columns = response.data.columns ? response.data.columns.map(x => x.name) : [];
                this.setState({
                    session: response.data,
                    resultId: response.data.calculationResultId,
                    fields: columns,
                    isHeadersRowPresented: response.data.isHeadersRowPresented
                });
                return response.data.calculationResultId
            }) 
            .then(
                (resultId) => 
                    resultId ? 
                        getResult(sessionId, resultId)
                            .then(result => 
                                this.setState({
                                    calculationResult: result.data
                                })
                            ) : 
                        null
            )
            .finally(() => this.setState({
                loading: false
            }));
    }

    async delete() {
        if (this.state.session && window.confirm(`Are you sure to delete all data for ${this.state.session.fileName}`)) {
            this.setState({
                loading: true,
                session: null
            });
            await deleteSession(this.state.session.id);
            this.setState({
                toList: true
            });
        }
    }

    abortRequest () {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }
    }

    async onCalculate() {
        if (this.state.session) {
            await this.calculate({
                sessionId: this.state.session?.id,
                columns: this.state.fields.map((item, i) => ({
                    index: i,
                    name: item
                })),
                decomposition: this.state.decomposition,
                isHeadersRowPresented: this.state.isHeadersRowPresented
            })
        }
    }

    async onRecalculate(payload: CalculatePyload) {
        await this.calculate({
            sessionId: this.state.session?.id,
            columns: payload.columns ? payload.columns : this.state.fields.map((item, i) => ({
                index: i,
                name: item
            })),
            decomposition: payload.decomposition,
            isHeadersRowPresented: this.state.isHeadersRowPresented
        });
    }

    async calculate(payload: CalculatePyload) {
        this.setState({
            isCalculation: true
        });
        try {
    
            let calculationResult = (
                    await calculate(
                        {
                            sessionId: payload.sessionId, 
                            columns: payload.columns,
                            graphBinsCount: 1000,
                            decomposition: payload.decomposition,
                            isHeadersRowPresented: payload.isHeadersRowPresented
                        }, 
                        this.abortController
                    )
                ).data;           
    
            this.setState({
                calculationResult: calculationResult,
                resultId: calculationResult.resultId
            });
                
        } catch(err) {
            const axiosError = (err as AxiosError);
            if (axiosError.code === 'ERR_BAD_RESPONSE') {
                
                showErrorAlert({
                    title: 'Error',
                    children: 'Error calculation',
                    isAlert: true
                });
            }  
        }

        this.setState({
            isCalculation: false
        });
    }

    showAllChartTypes() {
        this.setState({
            displayChartList: [ChartType.DECOMPOSITION, ChartType.SIGNIFANCE]
        })
    }

    hideAllChartTypes() {
        this.setState({
            displayChartList: []
        })
    }

    toggleChartType(chartType: ChartType) {
        this.state.displayChartList.includes(chartType) ? 
            this.setState((state) => ({
                displayChartList: state.displayChartList.filter(item => item !== chartType)
            })):
            this.setState((state) => ({
                displayChartList: [...state.displayChartList, chartType]
            }));
    }

    updateCalculatePayload(payload: CalculatePyload) {
        this.setState({
            decomposition: payload.decomposition
        });
    }

    render() {
        return (
                <>
                    {this.state.toList && <Redirect to='/analysis'></Redirect>}
                    {
                        <Box className={styles.Results}>

                            <Box className={styles.header}>
                                Results
                            </Box>
                            <Box className={styles.fileName}>
                                Results for: <span className={styles.name}>{this.state.session?.fileName}</span>
                            </Box>
                            <Box className={styles.additionalInformation}>
                                <Box className={styles.date}>
                                    {
                                        this.state.session &&
                                        <>
                                            {
                                                new Date(this.state.session?.createDate)
                                                    .toLocaleDateString(
                                                        'en-us', 
                                                        { month:"short", year:"numeric", day: 'numeric',
                                                    })
                                            } 
                                            <span className={styles.time}>
                                                {new Date(this.state.session?.createDate).getHours()} :
                                                {new Date(this.state.session?.createDate).getMinutes()}
                                            </span>
                                        </>
                                    }
                                </Box>
                                <Box className={styles.session}>
                                    Session ID: {this.state.session?.id}    
                                </Box>
                            </Box>
                            <Box className={styles.FieldsArea}>
                                <ChipListComponent
                                    label="Fields"
                                    items={this.state.fields}
                                    onChange={this.onFieldsChange}/>
                            </Box>
                            <Box>
                                <FormControlLabel control={
                                    <Checkbox checked={!this.state.isHeadersRowPresented} onClick={this.onSkipHeadersRowClick}/>
                                } label="I don’t have headers" />
                            </Box>
                            <Divider className={styles.divider}/>
                            <Box className={styles.ActionArea}>
                                <Box className={styles.grid}>
                                    <CropDinIcon 
                                        onClick={() => this.setGridType(GridType.COLOMN)} 
                                        classes={{
                                            root: styles.gridIcon
                                        }}
                                        sx={{
                                            color: this.state.gridType === GridType.COLOMN ? 
                                                '#2497ED' : ''
                                        }}/>
                                    <GridViewIcon 
                                        onClick={() => this.setGridType(GridType.ROW)}
                                        classes={{
                                            root: styles.gridIcon
                                        }}
                                        sx={{
                                            color: this.state.gridType === GridType.ROW ? 
                                                '#2497ED' : ''
                                        }}/>
                                </Box>
                                <Box className={styles.chartView}>
                                    <ChartViewToggle value={this.state.mainChart} disabled={this.state.isCalculation || this.state.loading || !this.state.session} onChange={this.setMainChart}/>
                                </Box>
                                <Box className={styles.menu}>
                                    <Button
                                        classes={{
                                            disabled: styles.disabledMenuItem
                                        }}
                                        sx={{
                                            backgroundColor: this.state.displayChartList.includes(ChartType.SIGNIFANCE) ? 
                                                '#19974E' : 
                                                '#27AE60',
                                                '&:hover': {
                                                    backgroundColor: this.state.displayChartList.includes(ChartType.SIGNIFANCE) ? 
                                                        '#19974E' : 
                                                        '#27AE60',
                                                }
                                        }}
                                        disabled={this.state.isCalculation || this.state.loading || !this.state.session}
                                        onClick={() => this.toggleChartType(ChartType.SIGNIFANCE)}>
                                        <img src="/images/signifance.icon.svg"/>
                                        <span className={styles.buttonName}>Signifance</span>
                                    </Button>
                                    <Button                                         
                                        classes={{
                                            disabled: styles.disabledMenuItem
                                        }}
                                        sx={{
                                            backgroundColor: this.state.displayChartList.includes(ChartType.DECOMPOSITION) ? 
                                                '#EAB412' : 
                                                '#F2C94C',
                                                '&:hover': {
                                                    backgroundColor: this.state.displayChartList.includes(ChartType.DECOMPOSITION) ? 
                                                        '#EAB412' : 
                                                        '#F2C94C',
                                                }
                                        }} 
                                        disabled={this.state.isCalculation || this.state.loading || !this.state.session}
                                        onClick={() => this.toggleChartType(ChartType.DECOMPOSITION)}>
                                        <img src="/images/decomposition.icon.svg"/>
                                        <span className={styles.buttonName}>Decomposition set-up</span>
                                    </Button>
                                </Box>
                                <Box className={styles.buttons}>
                                    <Button 
                                        color="primary" 
                                        variant="outlined"
                                        onClick={() => 
                                            this.state.displayChartList.length === 2 ? 
                                                this.hideAllChartTypes() : 
                                                this.showAllChartTypes()
                                        } 
                                        disabled={this.state.isCalculation || this.state.loading || !this.state.session}>
                                        <span className={styles.buttonName}>
                                            {this.state.displayChartList.length === 2 ? 'Hide all' : 'Show all'}
                                        </span>
                                        {
                                            this.state.displayChartList.length === 2 ? 
                                                <ArrowBackIcon sx={{height: '17px'}}/> :
                                                <ArrowForwardIcon sx={{height: '17px'}}/>
                                        }
                                    </Button>          
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={this.onCalculate}
                                        disabled={
                                            this.state.isCalculation || 
                                            this.state.loading || 
                                            !this.state.session ||
                                            this.state?.currentUser ? 
                                                isLimitationErrorByUser(this.state.currentUser as UserData) :
                                                false
                                        }>
                                        {this.state.resultId ? "Recalculate" : "Calculate"}
                                    </Button>
                                </Box>
                            </Box>                            
                            <Provider store={store}>
                                {
                                    this.state.loading ?
                                    <LinearProgress/> :
                                    <>
                                        {
                                            (this.state.isCalculation ? 
                                                <CalculationProgress onCancel={this.abortRequest}></CalculationProgress> :
                                                <>
                                                    {
                                                        this.state.calculationResult &&
                                                        <Charts
                                                            displayChartList={[this.state.mainChart, ...this.state.displayChartList]}
                                                            onUpdate={this.onRecalculate}
                                                            data={this.state?.calculationResult}  
                                                            gridType={this.state.gridType}></Charts>
                                                    }
                                                </>)
                                        }
                                    </>

                                }
                            </Provider>
                        </Box>
                    }
                </>
                    
        );
    }
}