export const USAGE_VALUE = '$_USAGE_VALUE_$';
export const LIMIT_VALUE = '$_LIMIT_VALUE_$';

export const ERROR_LIMIT = 10;
export const WARNING_LIMIT = 20;

const LIMITATIONS = [
    {
        name: "Calculations",
        description: `You have used ${USAGE_VALUE} out of ${LIMIT_VALUE} transactions.`,
        measureKey: 'calculationCount',
        iconPath: '/images/calculation.icon.svg'
    },
    {
        name: "Storage",
        description: `You have used ${USAGE_VALUE} out of ${LIMIT_VALUE}`,
        measureKey: 'storageBytes',
        iconPath: '/images/storage.icon.svg'
    }
];

export default LIMITATIONS