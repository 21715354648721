import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LIMITATIONS, { USAGE_VALUE, LIMIT_VALUE } from "../../libs/const/limitation.const";
import { LicensingData } from "../../libs/models/licensingData.model";
import { formatBytes } from "../../libs/services/bytes.service";
import { isLimitationError, isLimitationWarning } from "../../libs/utils/Limitation";
import styles from './LimitationCard.module.scss';

export interface LimitationCardProps {
    licensingInfo?: LicensingData,
    open?: boolean
}

const LimitationCard: FC<LimitationCardProps> = (props) => {
    
    const [isError, setIsError] = useState<boolean>();
    const [isWarning, setIsWarning] = useState<boolean>();

    const classes = {
        colorPrimary: styles.colorPrimary,
        barColorPrimary: styles.barColorPrimary,
        root: styles.progressRoot
    }

    useEffect(() => {
        if (
            (props?.licensingInfo as any)?.actualMeasure && 
            (props?.licensingInfo as any)?.plan
        ) {
            if (
                LIMITATIONS.some(item => 
                    isLimitationError(
                        ((props?.licensingInfo as any)?.actualMeasure?.[item.measureKey]),
                        (props?.licensingInfo as any)?.plan?.measure?.[item.measureKey]
                    )
                )
            ) {
                setIsError(true);
                setIsWarning(false);
            } else if (
                LIMITATIONS.some(item =>
                    isLimitationWarning(
                        (props?.licensingInfo as any)?.actualMeasure?.[item.measureKey],
                        (props?.licensingInfo as any)?.plan?.measure?.[item.measureKey]
                    )
                )
            ) {
                setIsError(false);
                setIsWarning(true);
            } else {
                setIsError(false);
                setIsWarning(false);
            }
        }
    })

    return (
        <>
            {
                !props.open ? 
                    <Box className={styles.limitationCardClose}>
                        {
                            props?.licensingInfo?.actualMeasure &&
                            props?.licensingInfo?.plan ?
                                LIMITATIONS.map((limit: any) => (
                                    <Box
                                        key={limit.measureKey}
                                        className={`
                                            ${styles.limitationCardCloseItem}
                                            ${
                                                isLimitationError(
                                                    ((props?.licensingInfo as any)?.actualMeasure?.[limit.measureKey]),
                                                    (props?.licensingInfo as any)?.plan?.measure?.[limit.measureKey] 
                                                ) ? styles.warning : ''
                                            }
                                            ${
                                                isLimitationWarning(
                                                    (props?.licensingInfo as any)?.actualMeasure?.[limit.measureKey],
                                                    (props?.licensingInfo as any)?.plan?.measure?.[limit.measureKey]
                                                ) ? styles.error : ''
                                            }
                                        `}
                                        title={
                                            
                                            limit.description
                                            .replace(
                                                USAGE_VALUE, 
                                                limit.measureKey === 'storageBytes' ? 
                                                    formatBytes((props?.licensingInfo as any)?.actualMeasure?.[limit.measureKey])
                                                    : (props?.licensingInfo as any)?.actualMeasure?.[limit.measureKey]
                                            )
                                            .replace(
                                                LIMIT_VALUE, 
                                                limit.measureKey === 'storageBytes' ? 
                                                    formatBytes((props?.licensingInfo as any)?.plan?.measure?.[limit.measureKey])
                                                    : (props?.licensingInfo as any)?.plan?.measure?.[limit.measureKey]
                                            )
                                        }>
                                        <img src={limit.iconPath}/>
                                    </Box>
                                )) :
                                <Box className={`${styles.limitationCardCloseItem} ${styles.limitationCardCloseItemLoader}`}>
                                    <CircularProgress />
                                </Box>
                        }
                    </Box> :
                    <Box 
                        className={`
                            ${styles.limitationCard} 
                            ${isWarning ? styles.warning : ''} 
                            ${isError ? styles.error : ''}
                        `}>
                        <>
                            {
                                props?.licensingInfo?.actualMeasure &&
                                props?.licensingInfo?.plan ?                        
                                    LIMITATIONS.map((limit: any) => (
                                        <Box 
                                            key={limit.measureKey}
                                            className={`
                                                ${styles.limitationItem}
                                                ${
                                                    isLimitationError(
                                                        ((props?.licensingInfo as any)?.actualMeasure?.[limit.measureKey]),
                                                        (props?.licensingInfo as any)?.plan?.measure?.[limit.measureKey] 
                                                    ) ? styles.warning : ''
                                                }
                                                ${
                                                    isLimitationWarning(
                                                        (props?.licensingInfo as any)?.actualMeasure?.[limit.measureKey],
                                                        (props?.licensingInfo as any)?.plan?.measure?.[limit.measureKey]
                                                    ) ? styles.error : ''
                                                }
                                            `}>
                                            <Box className={`title ${styles.title}`}>
                                                {limit.name}
                                            </Box>
                                            <Box className={styles.description}>
                                                {
                                                    limit.description
                                                        .replace(
                                                            USAGE_VALUE, 
                                                            limit.measureKey === 'storageBytes' ? 
                                                                formatBytes((props?.licensingInfo as any)?.actualMeasure?.[limit.measureKey])
                                                                : (props?.licensingInfo as any)?.actualMeasure?.[limit.measureKey]
                                                        )
                                                        .replace(
                                                            LIMIT_VALUE, 
                                                            limit.measureKey === 'storageBytes' ? 
                                                                formatBytes((props?.licensingInfo as any)?.plan?.measure?.[limit.measureKey])
                                                                : (props?.licensingInfo as any)?.plan?.measure?.[limit.measureKey]
                                                        )
                                                }
                                            </Box>
                                            <LinearProgress 
                                                classes={classes} 
                                                variant="determinate" 
                                                value={
                                                    ((props?.licensingInfo as any)?.actualMeasure?.[limit.measureKey]*100)
                                                    /(props?.licensingInfo as any)?.plan?.measure?.[limit.measureKey]
                                                } />
                                        </Box>
                                    )) :
                                    <Box className={styles.loaderWrapper}>
                                        <CircularProgress />
                                    </Box>
                            }
                        </>
                        <Box className={styles.footer}>
                            <Box>Need more?</Box>
                            <Link className={styles.link} to={'/account?tab=plans-pricing'}>Upgrade plan</Link>
                        </Box>
                    </Box>
            }
        </>
    );
}

export default LimitationCard;