import { Box, LinearProgress } from '@mui/material';
import React, {FC, useEffect, useState} from 'react';
import { Measure, Plan } from '../../../libs/models/licensingData.model';
import { UserData } from '../../../libs/models/userData.model';
import { CurrentUser } from '../../../libs/observables/CurrentUser';
import {applyLicensingPlan, getContactEmail, getLicensingPlans} from '../../../libs/services/licensing.service';
import PlanCard from '../../components/PlanCard/PlanCard';
import TransactionsProgress from '../../components/TransactionsPropgress/TransactionsPropgress';
import styles from './PlansAndPricing.module.scss';
import EmailContactCard from '../../components/EmailContactCard/EmailContactCard';
import objectHash from 'object-hash';

interface PrivacyAndSecurityProps {}

const PlansAndPricing: FC<PrivacyAndSecurityProps> = () => {

    const [planList, setPlanList] = useState<Plan[]>();
    const [loaded, setLoaded] = useState<boolean>();
    const [actualMeasure, setActualMeasure] = useState<Measure>();
    const [currentPlan, setCurrentPlan] = useState<Plan>();
    const [contactEmail, setContactEmail] = useState<string>();

    useEffect(() => {
        if (!loaded) {
            Promise.all([
              getLicensingPlans(),
              getContactEmail()
            ]).then(([data, contactEmail]) => {
                setLoaded(true);
                setPlanList(data.data);
                setContactEmail((contactEmail as any).data);
            })
        }
    }, [loaded]);

    useEffect(() => {
      const subscription = CurrentUser.currentUser$.subscribe((value: UserData) => {
        if (value.licensingInfo) {
          setActualMeasure(value.licensingInfo?.actualMeasure);
          setCurrentPlan(value.licensingInfo?.plan);
        }
      });
      return () => subscription.unsubscribe();
    })

    function onApplyPlan(plan: Plan){
        setLoaded(false);
        applyLicensingPlan(plan).then(response => {
            CurrentUser.currentUser$.next(response.data);
        });
    }

    return (
      <Box className={styles.PlansAndPricing}>
          {
              !loaded && <LinearProgress />
          }
        <Box className={styles.header}>
          <Box className='title'>Plans & Pricing</Box>
          <Box className='subTitle'>Simple plans. Choose your very best option</Box>
        </Box>
        <>
          {
            actualMeasure &&
            currentPlan &&
            <div>
                <TransactionsProgress 
                wrapperClassName={styles.transactionsPropgress}
                title={'Storage usage'}
                isByte={true}
                applay={actualMeasure.storageBytes || 0} 
                count={currentPlan.measure?.storageBytes || 0} />
              <TransactionsProgress 
                wrapperClassName={styles.transactionsPropgress}
                title={'Calculations'}
                applay={actualMeasure.calculationCount || 0} 
                count={currentPlan.measure?.calculationCount || 0} />
            </div>
          }
          </>
        <Box className={styles.planArea}>
          {
            planList?.map((plan, index) => (
              <PlanCard 
                key={objectHash(plan)}
                plan={plan}
                onApplyPlan={onApplyPlan}
                active={plan.planType === currentPlan?.planType} />
            ))
          }
          {
            contactEmail && <EmailContactCard email={contactEmail}></EmailContactCard>
          }          
        </Box>
      </Box>
    );
}

export default PlansAndPricing;
