import { LogLevel } from "@azure/msal-browser";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_susi",
        editProfile: "B2C_1_edit_profile",
        resetPassword: "B2C_1_reset_password"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://SimDec.b2clogin.com/SimDec.onmicrosoft.com/B2C_1_susi"
        },
        editProfile: {
            authority: "https://SimDec.b2clogin.com/SimDec.onmicrosoft.com/B2C_1_edit_profile"
        },
        resetPassword: {
            authority: "https://SimDec.b2clogin.com/SimDec.onmicrosoft.com/B2C_1_reset_password"
        }
    },
    authorityDomain: "SimDec.b2clogin.com"
}

// Config object to be passed to Msal on creation
// @ts-ignore
// @ts-ignore
// @ts-ignore
export const msalConfig = {
    auth: {
        clientId: "7878df37-f180-4931-9617-001f30d6c2af",
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

// export const resetPasswordRequest = {
//     scopes:[]
// }

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
    /*scopes: ["https://simdec.onmicrosoft.com/simdec/read", "https://simdec.onmicrosoft.com/simdec/write"]*/
    scopes: ["https://simdec.onmicrosoft.com/tasks-api/tasks.read"]
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig = {
    scopes: [/*'https://simdec.onmicrosoft.com/0be5a1d4-ef93-44e6-a331-a68e8c60a076/tasks.read',
        'https://simdec.onmicrosoft.com/0be5a1d4-ef93-44e6-a331-a68e8c60a076/tasks.write'*/],
    uri: 
        'https://simdecfunctionsdev.azurewebsites.net/api' 
        // 'https://simdecfunctionstest.azurewebsites.net/api'
        // 'http://localhost:7071/api'
        // 'https://simdecfunctions.azurewebsites.net/api'
};
