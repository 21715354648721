export enum PlanType {
    Personal,
    Professional,
    Team
}

export const PLAN_DESCRIPTION = {
    [PlanType.Personal]: 'Perfect plan for Starters',
    [PlanType.Professional]: 'For users who want to do more',
    [PlanType.Team]: 'Your entire team in one place',
}

export type Measure = {
    "calculationCount": number,
    "storageBytes": number,
    "invitedUsersCount": number
};

export type Plan = {
    "planType": PlanType,
    "price": number,
    "measure": Measure
}

export type LicensingData = {
    id: string,
    ownerId: string,
    createDateUtc: string,
    plan: Plan,
    actualMeasure: Measure
};