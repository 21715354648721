import React, {FC, useRef} from 'react';
import styles from './UploadFileComponent.module.scss';
import { FileDrop } from 'react-file-drop';
import {Icon} from "@mui/material";
import "./DnD.scss";

export interface UploadFileComponentProps {
    onFileAdd:(file:File)=>void;
    disabled?: boolean,
    title?: string
}

const UploadFileComponent: FC<UploadFileComponentProps> = (props) => {
    const fileInputRef = useRef(null);
    
    const onFileInputChange = (event:any) => {
        const { files } = event.target;
        uploadFile(files, event);
    }

    const onTargetClick = () => {
        // @ts-ignore
        fileInputRef.current.click()
    }
    
    function uploadFile(files: FileList | null, event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
        if (files !=null && files.length === 1 && !props.disabled)
        {
            props.onFileAdd(files[0]);
        }
    }

    return (
        <div className={`
            ${styles.UploadFileComponent}
            ${props.disabled ? styles.disabled : ''}
        `}
        title={props.title}>
            <input
                onChange={onFileInputChange}
                ref={fileInputRef}
                type="file"
                className={styles.hidden}
                disabled={props.disabled}
            />
            <FileDrop
                onDrop={(files, event) => uploadFile(files, event)}
                onTargetClick={onTargetClick}
            >
                <Icon sx={{marginBottom: '12px'}}><img alt="" src="/images/uploadButton.svg"/></Icon>
                <span className={styles.blob}>Click to upload</span> or drag and drop <br/>
                {props.children}
            </FileDrop>
        </div>
    );
}

export default UploadFileComponent;
