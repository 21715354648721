import React, { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import styles from './SortComponent.module.scss';
import { DataFilter } from '../../libs/services/session.service';
import { Divider, InputAdornment, OutlinedInput } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import {DataTableSorting} from "../DataTable/DataTable";

export interface SortComponentProps {
    sorting: Array<DataTableSorting>,
    filter: DataFilter,
    onFilter: (filter: DataFilter) => void
}

const SortComponent: FC<SortComponentProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuFilter = (
      event: React.MouseEvent<HTMLElement>,
      fild: string,
    ) => {
        if (fild !== props.filter.sortField) {
            props.onFilter({
                ...props.filter,
                sortField: fild
            });
        }
    };
  
    const handleMenuSort = (
      event: React.MouseEvent<HTMLElement>,
      ascending: boolean,
    ) => {
        if (ascending !== props.filter.ascending) {
            props.onFilter({
                ...props.filter,
                ascending
            });
        }
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <div className={styles.ArchiveTableFilter}>
        <OutlinedInput 
            id="lock-button"
            onClick={handleClickListItem}
            disabled
            classes={{
                root: styles.input,
                notchedOutline: open ? styles.notchedOutlineOpen : '',
                disabled: open ? styles.innerInputOpen : styles.innerInputClose,
                input: open ? styles.innerInputOpen : styles.innerInputClose
            }}
            value={props.sorting.find(item => item.field === props.filter.sortField)?.headerName}
            startAdornment = {
                <InputAdornment position="end">
                    {
                        open ? 
                            <ExpandLessIcon classes={{
                                root: styles.openIcon
                            }}/> : 
                            <ExpandMoreIcon classes={{
                                root: styles.closeIcon
                            }}/>
                    }
                </InputAdornment>
              }/>
            <DropDownMenu 
                handleClose={handleClose} 
                anchorEl={anchorEl} 
                open={open} 
                id={'lock-button'}>
                    {props.sorting.map(item => 
                        <MenuItem
                            key={item.field}  
                            selected={props.filter?.sortField === item.field}
                            onClick={(event) => handleMenuFilter(event, item.field)}>
                            {item.headerName} 
                        </MenuItem>
                    )}
                    <Divider />
                    <MenuItem
                        key={'Descending'}
                        selected={props.filter?.ascending === false}
                        onClick={(event) => handleMenuSort(event, false)}>
                        Descending 
                    </MenuItem>
                    <MenuItem
                        key={'Ascending'}
                        selected={props.filter?.ascending === true}
                        onClick={(event) => handleMenuSort(event, true)}>
                        Ascending 
                    </MenuItem>
            </DropDownMenu>
        </div>
    );
}

export default SortComponent;
