import { FC, useState } from "react";
import { RootState } from "../../store/store";
import { 
    selectRequirements,
    selectScenariosData,
    toReadable,
} from "../../store/statesStore";
import { 
    setColor as actionSetColor
} from "../../store/colorStore";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";
import styles from './ScenariosContainer.module.scss';
import { GridType } from "../../../pages/Results/Results";
import { Collapse } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import objectHash from "object-hash";

interface ScenariosContainerProps {
    gridType: GridType
}

const ScenariosContainer: FC<ScenariosContainerProps> = (props) => {

    const dispatch = useDispatch();

    const scenariosData = useSelector((state: RootState) => selectScenariosData(state));
    const requirements = useSelector((state: RootState) => selectRequirements(state));

    const setColorToState = (scIndex: any, color: any) => {
        if (color) {
            dispatch(actionSetColor({scIndex, color}))
        }
    }
    const [color, setColor] = useState('');
    
    const updateColor = (eventData: any) => {
        setColor(eventData.hex);
    }
    
    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setColor('')
        setChecked((prev) => !prev);
    };
    
    return (
        <> 
            {
               scenariosData.length > 0 &&
                <div className={`
                    ${styles.scenariosContainer} 
                    ${props.gridType === GridType.ROW ? styles.scenariosContainerColomn : ''}
                `}>
                   <div className={styles.title}>
                        Scenarios
                        <img onClick={handleChange} src="/images/palete.icon.svg" />
                    </div>
                    <div className={styles.tableWrapper}>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <th rowSpan={2}>Color</th>
                                    <th rowSpan={2}>Scenario</th>
                                    {
                                            (requirements as any)?.map((requirement: any) => (
                                                <th key={requirement?.name} rowSpan={2}>
                                                    {requirement?.name}
                                                </th>
                                            ))
                                    }
                                    <th colSpan={3}>DCF sum</th>
                                    <th rowSpan={2}>Probability</th>
                                </tr>
                                <tr>
                                    <th>Min</th>
                                    <th>Avg</th>
                                    <th>Max</th>
                                </tr>
                                {
                                        scenariosData?.map((scenario: any, scIndex: number) => {
                                            return (                                        
                                                <tr key={objectHash(scenario)} className={styles.dataRow}>
                                                    <th>
                                                        <div 
                                                            className={styles.colorButton}
                                                            onClick = {() => setColorToState(scIndex, color)}
                                                            style={{
                                                                backgroundColor:scenario.color 
                                                            }} />
                                                    </th>
                                                    <th>sc_{ scIndex + 1 }</th>
                                                    {
                                                        scenario?.requirementsWeighted?.map((requirement: any, index: number) => 
                                                            <th 
                                                                rowSpan ={requirement.weight} 
                                                                key={objectHash(index)}>
                                                                {requirement.data.state.type}
                                                            </th>
                                                        )
                                                    }
                                                    <th>{ toReadable(scenario?.min) }</th>
                                                    <th>{ toReadable(scenario?.average) }</th>
                                                    <th>{ toReadable(scenario?.max) }</th>
                                                    <th>{ Math.round(scenario?.probability) }%</th>
                                                </tr>
                                            );
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
               </div>
            }
            {
                scenariosData.length > 0 &&
                <Collapse className={styles.pickerWrapper} in={checked}>
                    <SketchPicker className={styles.SketchPicker} color={color} onChangeComplete={updateColor} />  
                    <CloseIcon onClick={handleChange} className={styles.closeIcon}/>
                </Collapse> 
            }
        </>
    );
}

export default ScenariosContainer;