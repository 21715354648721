import {postRequest} from "./http.service";
import {AxiosProgressEvent, AxiosRequestConfig} from "axios";

export interface UploadOption {
    progressCallback?: (progressEvent: AxiosProgressEvent) => void,
    abortController?: AbortController
}

export function upload(sessionId:string, file:File, option?: UploadOption) {
    const params = {
        sessionId,
        fileName: file.name
    };
    
    let uploadOption:AxiosRequestConfig  = {
        params,
        data: file,
    };
    if (option?.abortController) {
        uploadOption = {
            ...uploadOption,
            signal: option.abortController.signal
        }
    }
    if (option?.progressCallback) {
        uploadOption = {
            ...uploadOption,
            onUploadProgress: option.progressCallback
        }
    }

    return postRequest('/upload', uploadOption);
}
