
import {Box, Button, CircularProgress} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import styles from './AlertLayout.module.scss';
import {
    alertSubject,
    displayAlertSubject,
    AlertSettings,
    ALERT_TYPE_TO_CLASS,
    hideAlert, displayProgressSubject, showProgress, hideProgress
} from '../alert.service';
import CloseIcon from '@mui/icons-material/Close';

const AlertLayout: FC = (props) => {

    const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
    const [isShowProgress, setIsShowProgress] = useState<boolean>(false);
    const [alertSettings, setAlertSettings] = useState<AlertSettings>({});

    useEffect(() => {
        const subscription = displayProgressSubject.subscribe(value =>
            setIsShowProgress(value)
        );
        return () => subscription.unsubscribe();
    });
    
    useEffect(() => {
      const subscription = displayAlertSubject.subscribe(value => 
        setIsShowAlert(value)
      );
      return () => subscription.unsubscribe();
    });

    useEffect(() => {
      const subscription = alertSubject.subscribe(value => 
        setAlertSettings(value)
      );
      return () => subscription.unsubscribe();
    });

    return (
      <>
          {
              isShowProgress &&
              <Box className={styles.alertLayout}>
                  <CircularProgress className={styles.progress}/>
              </Box>
          }
          {
              isShowAlert && 
              <Box className={`
                ${styles.alertLayout}
                ${
                  alertSettings.alertType ? 
                  styles[ALERT_TYPE_TO_CLASS[alertSettings.alertType]] : 
                    ''
                }
              `}>
                  <Box className={styles.content}>
                    <Box className={styles.title}>
                      <Box className={styles.text}>
                        {alertSettings.title ? alertSettings.title : ''}
                      </Box>
                      <CloseIcon 
                        onClick={() => hideAlert()}
                        className={styles.closeIcon}/>
                    </Box>
                    <Box className={styles.description}>
                      {alertSettings.children}
                      <Box className={styles.actionArea}>
                        {
                          !alertSettings.isAlert &&
                          <Button 
                            onClick={() => alertSettings.onCancel ? alertSettings.onCancel() : hideAlert()}
                            className={styles.actionButton} 
                            variant="outlined">
                            {
                              alertSettings.cancelBtnTitle ? alertSettings.cancelBtnTitle : 'Cancel'
                            }
                          </Button>
                        }
                        <Button 
                          onClick={() => alertSettings.onAction ? alertSettings.onAction() : hideAlert()}
                          className={styles.actionButton} 
                          variant="contained">
                          {
                            alertSettings.actionBtnTitle ? alertSettings.actionBtnTitle : 'ok'
                          }
                        </Button>
                      </Box>
                    </Box> 
                  </Box>
              </Box>
          }
      </>
    );
}

export default AlertLayout;