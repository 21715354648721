import { Box, Button } from "@mui/material";
import { FC } from "react";
import styles from './PlanCard.module.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Plan, PlanType, PLAN_DESCRIPTION } from "../../../libs/models/licensingData.model";
import {formatBytes} from "../../../libs/services/bytes.service";

interface PlanCardProps {
  active: boolean;
  plan: Plan,
  onApplyPlan: (plan:Plan)=>void;
}

const PlanCard: FC<PlanCardProps> = (props) => {
  function applyPlan(){
    if (!props.active) {
        props.onApplyPlan(props.plan);
      }
    }
    
    return (
      <Box className={`${styles.card} ${props.active ? styles.activeCard : styles.deactiveCard}`}>
        <Box className={styles.header}>
          <Box className={styles.headerTitle}>
            <Box className={styles.title}>{PlanType[props.plan.planType]}</Box>
            <img src='/images/icon-logo.icon.svg' />
          </Box>
          <Box className={styles.description}>{PLAN_DESCRIPTION[props.plan.planType]}</Box>
        </Box>
          <Box className={styles.price}>
            {
              props.plan.price ? 
              <>
                ${props.plan.price}
                <Box className={styles.period}>/year</Box> 
              </>: 
              <>
                {props.plan.measure.calculationCount} FREE
                <Box className={styles.period}>Calculations</Box> 
              </>
            }
          </Box>
          <Button className={styles.actionBtn} onClick={applyPlan}>
            {
              props.active ? 
                'Current Plan' :
                'Apply Plan'
            }
          </Button>
          <Box className={styles.optionList}>
            <Box className={styles.optionItem}>
              <CheckCircleIcon className={styles.optionIcon}/>
              {`${props.plan.measure.calculationCount} calculations`}
            </Box>
            <Box className={styles.optionItem}>
              <CheckCircleIcon className={styles.optionIcon}/>
              {`${formatBytes(props.plan.measure.storageBytes)} storage`}
            </Box>
            <Box className={styles.optionItem}>
              <CheckCircleIcon className={styles.optionIcon}/>
              {
                  props.plan.measure.invitedUsersCount >= 0 ? 
                      `${props.plan.measure.invitedUsersCount} team members` : 
                      "Unlimited team members"
              }
            </Box>
            <Box className={styles.optionItem}>
              <CheckCircleIcon className={styles.optionIcon}/>
              Unlimited exports
            </Box>
          </Box>
      </Box>
    )
}

export default PlanCard;