import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
	addInput, 
	selectorSelectedInputs,
	selectAvailableInputs,
	setIsAutomatic,
	selectSelectedIndex,
	setSelectedIndex,
} from "../../store/statesStore";
import { RootState } from "../../store/store";
import DecompositionInput from "../DecompositionInput/DecompositionInput";
import './DecompositionContainer.scss';
import { CalculatePyload } from "../../../libs/services/calculate.service";

export interface DecompositionContainerProps {
	onUpdate: (payload: CalculatePyload) => void
}

const DecompositionContainer: FC<DecompositionContainerProps> = (props) => {

	const dispatch = useDispatch();

	const selectedInputs = useSelector((state: RootState) => selectorSelectedInputs(state));
	
	const isAutomatic = useSelector((state: RootState) => state.statesStore.isAutomatic);

	const metadata = useSelector((state: RootState) => state.statesStore.metadata);

	const availableInputs = useSelector((state: RootState) => selectAvailableInputs(state));

	const selectedIndex = useSelector((state: RootState) => selectSelectedIndex(state))

	const run = async () => {
		let decomposition = [];
		let columns = [];

		if (metadata !== undefined && metadata != null) {
			for (let i = 0; i < (metadata as any).length; i++) {
				columns[i] = {
					index: (metadata[i] as any).index,
					name: (metadata[i] as any).name,
					orderedCategories: (metadata[i] as any).orderedCategories
				};
			}

			for (let i = 0; i < selectedInputs.length; i++) {
				decomposition[i] = {
					index: (selectedInputs[i] as any).index,
					states: (selectedInputs[i] as any).states
				};

				columns[(selectedInputs[i] as any).index].orderedCategories = (selectedInputs[i] as any).categories;
			}				
		}

		const payload = {
			graphBinsCount: 1000,
			decomposition: decomposition.length > 0 ? decomposition : null,
			columns: columns.length > 0 ? columns : null
		};

		props.onUpdate(payload);
	}

	const onAddInput = () => 
		dispatch(addInput({index: selectedIndex}));

	const onSelect = (event: any) =>{
		dispatch(setSelectedIndex({selectedIndex: event?.target?.value}))
	};
	
	return (
		<div>
			<div className="decompHeader">
				<div className="decompHeaderTitle">
					Decomposition set-up
					<button onClick={run}>Update decomposition</button>
				</div>
			<div className="decompHeaderSwitch">
					<input 
						type="checkbox" 
						checked={isAutomatic} 
						onChange={() => dispatch(setIsAutomatic({isAutomatic: !isAutomatic}))} /> Automatic
			</div>
			</div>
				{
					availableInputs.length > 0 && !isAutomatic &&
					<div className="decompItemContainer">
						<div className="decompContainerSwitch">
							<select defaultValue={selectedIndex ||  availableInputs[0].value} onChange={onSelect}>
								{
									availableInputs.map((item: any, index) => (
										item?.value &&
										<option key={item?.text} value={item?.value}>
											{ item?.text }
										</option>
									))
								}
							</select>
							<button className="button" onClick={onAddInput}>+</button>
						</div>
					</div>
				}
			<div className="decompContainer">
				{
					selectedInputs?.map((input, index) => (
						<DecompositionInput key={(input as any).name} input={input} index={index}/>
					))
				}
			</div>
		</div>
	)
}

export default DecompositionContainer;
