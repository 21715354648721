export enum ChartType {
    SIGNIFANCE,
    DECOMPOSITION,
    SCENARIOS,
    HISTOGRAM,
    BOXPLOTS
}

export const ChartTypeToName = {
    [ChartType.SIGNIFANCE]: 'Singafance',
    [ChartType.DECOMPOSITION]: 'Decomposition',
    [ChartType.SCENARIOS]: 'Scenarios',
    [ChartType.HISTOGRAM]: 'Probability',
    [ChartType.BOXPLOTS]: 'Box plots',
}