import {Plan} from "../models/licensingData.model";
import {UserData} from "../models/userData.model";
import {getRequest, postRequest} from "./http.service";

export async function getLicensingPlans() {
    return await getRequest<Plan[]>("/getLicensingPlans");
}

export async function getContactEmail() {
    return await getRequest<Plan[]>("/getContactEmail");
}

export async function applyLicensingPlan(plan: Plan) {
    return await postRequest<UserData>("/applyLicensingPlan", {data: plan});
}