import { Box, Button } from "@mui/material";
import { Title, Tooltip, BarElement, CategoryScale, LinearScale, Chart } from "chart.js";
import React, { FC, useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from "react-redux";
import { visiblePercentSelect, left, zoomIn, zoomOut, right, selectChartOptions, selectChartData } from "../../store/chartStore";
import { RootState } from "../../store/store";
import styles from './ChartContainer.module.scss';

const ChartContainer: FC = () => {

    const dispatch = useDispatch();

    const [visiblePercent, setVisiblePercent] = useState(0)

    const chartData = useSelector((state: RootState) => selectChartData(state));
    const chartOptions = useSelector((state: RootState) => selectChartOptions(state));
    const visiblePercentNew = useSelector((state: RootState) => visiblePercentSelect(state));
    const visibleCount = useSelector((state: RootState) => state.chartStore.visibleCount);
    
    useEffect(() => {
        setVisiblePercent(visiblePercentNew || 0);
    }, [visiblePercentNew !== visiblePercent]);
    
    Chart.register(Title, Tooltip, BarElement, CategoryScale, LinearScale);

    return (
        <>
            {
                visibleCount > 0 && 
                <Box className={styles.content}>
                    <Box>
                        <Bar data={(chartData as any)} options={(chartOptions as any)}/>
                    </Box>
                    <Box className={styles.actionArea}>
                        <Button onClick={() => dispatch(left())}>
                            <img src="/images/left.arrow.icon.svg"/>
                        </Button>
                        <Button className={styles.minus} onClick={() => dispatch(zoomOut())}>                                                   
                            <img src="/images/minus.icon.svg"/>
                        </Button>
                        <Button className={styles.plus} onClick={() => dispatch(zoomIn())}>                                              
                            <img src="/images/plus.icon.svg"/>
                        </Button>
                        <Button onClick={() => dispatch(right())}>                            
                            <img src="/images/right.arrow.icon.svg"/>
                        </Button>
                    </Box>
                </Box>
            }
        </>
    )
}

export default ChartContainer;
