import React, {FC, useEffect, useState} from 'react';
import styles from './Users.module.scss';
import {Box, Button, LinearProgress} from "@mui/material";
import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import {DataFilter} from "../../../libs/services/session.service";
import {getUsers} from "../../../libs/services/user.service";
import DataTable, {DataTableState, DataTableStyles} from "../../../components/DataTable/DataTable";
import { PlanType} from "../../../libs/models/licensingData.model";
import {formatBytes} from "../../../libs/services/bytes.service";
import UserInfo from "../../../components/UserInfo/UserInfo";
import { UserData } from '../../../libs/models/userData.model';

interface AccountUsersProps {}

const DEFAUL_USERS_STATE:DataTableState = {
    rows: [],
    filter: {
        sortField:'createDateUtc',
        pageNumber: 1,
        pageSize: 10,
        ascending: false
    },
    totalRows: 0,
    loading: false,
    rowId: null
}

type RowData = {
    id: string,
    user: UserData,
    createDateUtc?: string,
    plan?: string,
    calculationsCount?: number,
    usedStorageBytes: string
}

const AccountUsers: FC<AccountUsersProps> = () => {
    const [dataTableState, setDataTableState] = useState<DataTableState>(DEFAUL_USERS_STATE);
    const [loading, setLoading] = useState<boolean>(false);
    let abortController: AbortController = new AbortController();
    const columns: GridColDef[] = [
        {
            field: 'user',
            headerName: 'Name',
            flex: 0.4,
            sortable: false,
            headerClassName: DataTableStyles.HeaderCell,
            renderCell: (params) => {
                return <UserInfo showDescription={true} user={params.value} small={true}/>;
            }
        },
        {
            field: 'createDateUtc',
            headerName: 'Date added',
            flex: 0.13,
            sortable: false,
            headerClassName: DataTableStyles.HeaderCell,
            valueFormatter: (params : GridValueFormatterParams<string>) => {
                if (!params.value){
                    return "";
                }
                
                return new Date(params.value).toLocaleDateString('en-us', { month:"short", year:"numeric", day: 'numeric',})
            },
       },
        {
            field: 'plan',
            headerName: 'Plan',
            flex: 0.13,
            sortable: false,
            headerClassName: DataTableStyles.HeaderCell,
        },
        {
            field: 'calculationsCount',
            headerName: 'Calculations',
            flex: 0.07,
            sortable: false,
            headerClassName: DataTableStyles.HeaderCell,
        },
        {
            field: 'usedStorageBytes',
            headerName: 'Used storage',
            flex: 0.2,
            sortable: false,
            headerClassName: DataTableStyles.HeaderCell,
        },
        {
            field: "action",
            headerName: "",
            sortable: false,
            flex: 0.07,
            headerClassName: DataTableStyles.HeaderCell,
            renderCell: (params) => {
                return <span>Owner</span>;
            }
        },
    ]
    

    function filterChange(filter:DataFilter){
        setDataTableState({
            ...dataTableState,
            filter});
    }
    
    function abortRequest () {
        if (abortController) {
            abortController.abort();
            abortController = new AbortController();
        }
    }

    function loadUsers(filter: DataFilter) {
        abortRequest();
        setDataTableState({
            ...dataTableState,
            loading: true});
        setLoading(true);
        getUsers({
            data: filter,
            signal: abortController.signal
        }).then((response) => {
            const rows = response.data.items.map<RowData>((item)=> ({
                id: item.id,
                createDateUtc: item.licensingInfo?.createDateUtc,
                user: item,
                plan: item.licensingInfo ? PlanType[item.licensingInfo.plan.planType] : "",
                calculationsCount: item.licensingInfo?.actualMeasure.calculationCount,
                usedStorageBytes: formatBytes(item.licensingInfo ? item.licensingInfo.actualMeasure.storageBytes : 0)
            }));
            
            setDataTableState({
                ...dataTableState,
                rows: rows,
                totalRows: response.data.totalItemsCount,
                loading: false
            }) 
        })
        .finally(() => setLoading(false))
    }

    useEffect( () => {
        if (dataTableState.rows.length === 0) {
            loadUsers(dataTableState.filter);
        }
    }, [dataTableState.rows.length > 0]);
    
    return (
        <Box className={styles.root}>
        {
            loading && <LinearProgress />
        }
            <span className={styles.caption}>Members</span>
            <Box className={styles.detailsArea}>   
                <span className={styles.details}>Invite your team memmbers on Simdec to work faster and collaborate easily together.</span>
                <Button className={styles.button} variant={"outlined"} sx={{border: "1px solid #2497ED"}} disabled={true}>Invite a new
                    member</Button>
            </Box>
            <DataTable
                    tableState={dataTableState}
                    columns={columns}
                    onFilter={filterChange}
                    customClasses={`${DataTableStyles.Border} ${DataTableStyles.BottomHeaderBorder} ${DataTableStyles.HighRow}`}/>
        </Box>
    );
}

export default AccountUsers;
