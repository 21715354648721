import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';

export interface LinearProgressWithLabelProps {
    value: number
}

const LinearProgressWithLabel: React.FC<LinearProgressWithLabelProps> = ({ value }) => {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={value} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            value,
          )}%`}</Typography>
        </Box>
      </Box>
     );
    }

export default LinearProgressWithLabel;
