import React, { FC } from 'react';
import styles from './SignificanceContainer.module.scss';
import { RootState } from "../../store/store";
import { selectSignificance } from '../../store/statesStore'
import { useSelector } from "react-redux";

const SignificanceContainer: FC = () => {

    const significance = useSelector((state: RootState) => selectSignificance(state));
    
    return (
        <>
            {
                significance.length > 0 &&
                <div className={styles.significanceContainer}>
                    <div className={styles.title}>Significance</div>
                    <table>
                        <tbody>
                            {
                                significance.map((item: any) => (
                                    <tr key={item.name}>
                                        <td width="30%">{ item.name }</td>
                                        <td>
                                            <div className={styles.signPercent} style={{
                                                width: item.relativeValue + '%', 
                                                backgroundColor: 'rgb(' + (255 - (2.45 * item.relativeValue)) + ',' +  (255 - (2.45 * item.relativeValue)) + ',' +  (255 - (2.45 * item.relativeValue)) + ')', 
                                                color: item.relativeValue > 50 ? 'white' : 'black'}}>
                                                { item.value }%
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            }
        </>
    )    
}

export default SignificanceContainer;