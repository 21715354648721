import React, {FC, useEffect, useState} from 'react';
import styles from './AccountMyDetails.module.scss';
import {Avatar, Divider, LinearProgress, OutlinedInput, Stack} from "@mui/material";
import {Box, Button} from "@mui/material";
import UploadFileComponent from "../../../components/UploadFileComponent/UploadFileComponent";
import {imageFileExtensionList} from "../../../libs/services/fileExtension.service";
import {getUser, updateUser, uploadPhoto} from "../../../libs/services/user.service";
import {UserData} from "../../../libs/models/userData.model";
import {CurrentUser} from "../../../libs/observables/CurrentUser";

interface AccountMyDetailsProps {}

const AccountMyDetails: FC<AccountMyDetailsProps> = () => {
    const [originalUser, setOriginalUser] = useState<UserData>();
    const [user, setUser] = useState<UserData>();
    const [isLoaded, setLoaded] = useState(false);
    const [isChanged, setChanged] = useState(false);

    useEffect( () => {
        if (!originalUser) {
            getUser()
                .then((response) => {
                    const originalUser = response.data;
                    CurrentUser.currentUser$.next(originalUser);
                })
                .finally(() => setLoaded(true));
        }
    }, [originalUser?.id]);

    useEffect(() => {
        const subscription = CurrentUser.currentUser$.subscribe((value) => {
            setOriginalUser(value);
            if(!user?.id) {
                setUser(value);
            }
        });
        return () => subscription.unsubscribe();
    });
    
    function onFileAdd(file: File){
        uploadPhoto(file).then((response) => {
            if (user) {
                setUser({
                    ...user,
                    photoUrl: response.data
                });

                setChanged(true);
            }
        })
    }
    
    const setUserGivenName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (user) {
            setUser({
                ...user,
                givenName: e.target.value
            });
            setChanged(true);
        }
    }

    const setUserSurname = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (user) {
            setUser({
                ...user,
                surname: e.target.value
            });
            setChanged(true);
        }
    }
    
    const handleCancel = () => {
        setUser(originalUser);
        setChanged(false);
    };

    const handleSave = () => {
        if (isChanged && user) {
            setLoaded(false);
            updateUser(user)
                .then((response) => {
                   CurrentUser.currentUser$.next(response.data);
                    setLoaded(true);
                    setChanged(false);
                })
        }
    };
    
    let inputStyle = {root: styles.inputRoot, input: styles.inputElement};
    
    return (
        <div className={styles.AccountMyDetails}>
            {
                !isLoaded && <LinearProgress />
            }
            <Stack className={styles.stack} divider={<Divider/>}>
                <Box>
                    <Box>
                        <span className={styles.infoStyle}>Personal Info</span><br/>
                        <span className={styles.detailsStyle}>Update your photo and personal details here.</span>
                    </Box>
                    <Box className={styles.actionArea}>
                        <Button color="primary" variant="contained" disabled={!isChanged} onClick={handleCancel}>Cancel</Button>
                        <Button color="primary" variant="contained" disabled={!isChanged} onClick={handleSave}>Save</Button>
                    </Box>
                </Box>
                <Box>
                    <Box className={styles.caption}>Name</Box>
                    <Box className={styles.value}>
                        <OutlinedInput classes={inputStyle} value={user?.givenName} disabled={!isLoaded} onChange={setUserGivenName}/>
                        <OutlinedInput classes={inputStyle} value={user?.surname} disabled={!isLoaded} onChange={setUserSurname}/>
                    </Box>
                </Box>
                <Box>
                    <Box className={styles.caption}>Email address</Box>
                    <Box className={styles.value}><OutlinedInput classes={inputStyle} value={user?.email} disabled={true}/></Box>
                </Box>
                <Box>
                    <Box className={styles.caption}>
                        <span className={styles.caption}>Your photo</span><br/>
                        <span className={styles.detailsStyle}>This will be displayed on your profile.</span>
                    </Box>
                    <Box className={styles.value}>
                        <Avatar className={styles.avatar} src={user?.photoUrl} />
                        <UploadFileComponent  onFileAdd={onFileAdd}>{imageFileExtensionList.join(', ')}</UploadFileComponent>
                    </Box>
                </Box>
            </Stack>
        </div>
    );
}

export default AccountMyDetails;
