import { FC } from "react";
import { Box, Button } from "@mui/material";
import DecompositionState from "../DecompositionState/DecompositionState";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { addState, downCategory, removeInput, upCategory } from "../../store/statesStore";

interface DecompositionInputProps {
	input: any,
	index: any
}

const DecompositionInput: FC<DecompositionInputProps> = (props) => {

	const dispatch = useDispatch();

	const isAutomatic = useSelector((rootState: RootState) => rootState.statesStore.isAutomatic);

	const onRemoveInput = (index: any) => dispatch(removeInput({index}));

	const setUpCategory = (index: any, indexCat: any) => {
		dispatch(downCategory({inputIndex: index, index: indexCat}))
		dispatch(upCategory({inputIndex: index, index: indexCat}))
	}

	return (
		<Box className="decompItemContainer">
			<Box className="decompContainerSwitch">
				{ props.input.name }
				{
					!isAutomatic &&
					<Button onClick={() => onRemoveInput(props.index)} className="button">-</Button>
				}
			</Box>
			<Box className="decompContainerHeader">
				<Box>State</Box>
				<Box>Min</Box>
				<Box>Max</Box>
			</Box>
			{
				props.input?.states?.map((state: any, indexState: any) => (
					<DecompositionState 
						state={state} 
						index={indexState} 
						inputIndex={props.index} 
						isMaxDisabled={indexState === (props.input.states.length - 1)}/> 
				))
			}
			<Box className="decompContainerContent">
				{
					!isAutomatic &&
					<button 
						className="button" 
						onClick={() => dispatch(addState({inputIndex: props.index}))}>+</button>
				}
			</Box>
			{
				props.input.categories != null && 
				<Box className="decompContainerCategories">
					<Box className="catHeader">Categories</Box>
	
					<table>
						<tbody>
							{
								props.input?.categories?.map((category: any, indexCat: any) => (
									<tr key={category}>
										<td>{ indexCat }</td>
										<td>{ category }</td>
										<td>
											{
												!isAutomatic && indexCat !== (props.input.categories.length - 1) &&
												<button 
													className="button" 
													onClick={
														() => 
															dispatch(downCategory({inputIndex: props.index, index: indexCat}))
												}>&darr;</button>
											}
										</td>
										<td>
											{
												!isAutomatic && indexCat !== 0 &&
												<button 
													className="button" 
													onClick={() => dispatch(upCategory({inputIndex: props.index, index: indexCat-1}))}>&uarr;</button>
											}
											
										</td>
									</tr>
								))
							}
						</tbody>
					</table>
				</Box>
			}
		</Box>
	)
}

export default DecompositionInput;
