import {FC} from 'react';
import styles from './PrivacyAndSecurity.module.scss';
import { Box, Button } from "@mui/material";
import { Link } from 'react-router-dom';
import { loginRequest, b2cPolicies } from '../../../authConfig';
import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import {deleteUser} from "../../../libs/services/user.service";
import {hideAlert, hideProgress, showProgress, showWarningAlert} from "../../components/alerts/alert.service";

interface PrivacyAndSecurityProps {}

const PrivacyAndSecurity: FC<PrivacyAndSecurityProps> = () => {
    
    const { instance } = useMsal();
    const account: AccountInfo = instance.getAllAccounts()?.[0]

    const resetPassword = async () => {
        await instance.loginRedirect({
            ...b2cPolicies.authorities.resetPassword, 
            ...loginRequest,
            sid: account.idToken
        })
    }

    function deleteAccount() {
        showWarningAlert({
            title: 'Confirmation',
            children: "Are you sure to delete all user's data?",
            isAlert: false,
            onAction: () => {
                hideAlert();
                showProgress();
                deleteUser().then(async () => {
                    await instance.logout();
                }).finally(() => hideProgress())
            }
        })
    }

    return (
        <div className={styles.AccountMyDetails}>
            <Box className={styles.stack}>
                <Box>
                    <Button
                        onClick={() => resetPassword()}
                        variant="contained">
                            Change password
                        </Button>
                </Box>
                <Box className={styles.settings}>
                    <Box className={styles.item}>
                        <Box className={styles.title}>Delete Profile</Box>
                        <Box className={styles.description}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </Box>
                        <Box className={styles.action}>
                            <Button variant="outlined" onClick={deleteAccount}>Delete</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default PrivacyAndSecurity;
