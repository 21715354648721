import React from 'react';
import styles from './ChipListComponent.module.scss';
import {Chip, OutlinedInput, Stack, Box} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

export interface ChipListComponentProps {
    items: Array<string>,
    label: string,
    numberLimits?: number,
    onChange:(items:string[])=>void;
}

export class ChipListComponent extends React.Component<ChipListComponentProps, any> {
    
    constructor(props: ChipListComponentProps) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }
    
    handleKeyDown = (event:any) => {
        if(event.key === "Enter")
        {
            let items = Array.from(this.props.items);
            let item = event.target.value;
            
            if (!items.includes(item))
            {
                items.push(item);
                event.target.value = null;
                this.props.onChange(items);
            }
        }
    }

    handleDelete = (item:string, event:any) => {
        let items = Array.from(this.props.items);
        const index = items.indexOf(item);
        
        if (index > -1) {
            items.splice(index, 1);
            this.props.onChange(items);
        }
    }
    
    render () {
        return (
        <div className={styles.ChipListComponent}>
            <Stack direction={"row"} flexWrap={"wrap"} alignItems={"center"}>
                <Box className={styles.label}><span>{this.props.label}</span></Box>
                {
                    this.props.items.map(item => 
                        <Chip 
                            key={item}
                            label={item} 
                            variant="outlined" 
                            deleteIcon={<ClearIcon sx={{width: '12px'}}/>} 
                            onDelete={this.handleDelete.bind(this, item)} sx={{
                                height: '25px',
                                border: '1px solid #000000',
                                borderRadius: '3px',
                                margin: '5px',
                            }}/>
                    )
                }

                <OutlinedInput 
                    onKeyDown={this.handleKeyDown} 
                    disabled={
                        this.props.numberLimits !== undefined && 
                        this.props.numberLimits === this.props.items.length} 
                    sx={{
                        padding: '0px',
                        margin: '5px',
                        height: '25px',
                        '& input': {
                            paddingLeft: '12px',
                            paddingRight: '12px'
                        }
                    }}/>
            </Stack>
        </div>
        )};
}

export default ChipListComponent;
