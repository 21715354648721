import {deleteRequest, getRequest, postRequest} from "./http.service";
import {UserData} from "../models/userData.model";
import {AxiosRequestConfig} from "axios";
import {DataFilter} from "./session.service";
import {UploadOption} from "./fileUpload.service";

export interface GetUsersResponse {
    items: Array<UserData>,
    totalItemsCount: number
}

export async function getUsers(option: AxiosRequestConfig<DataFilter>) {
    return await postRequest<GetUsersResponse>('/getUsers', option);
}

export async function getUser() {
    return await getRequest<UserData>("/getUser");
}

export async function deleteUser() {
    return await deleteRequest("/deleteUser");
}

export async function updateUser(user: UserData) {
    return await postRequest<UserData>("/updateUser", {
        data: {
            ...user
        }
    });
}

export function uploadPhoto(file:File, option?: UploadOption) {
    const params = {
        fileName: file.name,
        contentType: file.type
    };
    
    let uploadOption:AxiosRequestConfig  = {
        params,
        data: file,
    };
    
    if (option?.abortController) {
        uploadOption = {
            ...uploadOption,
            signal: option.abortController.signal
        }
    }
    if (option?.progressCallback) {
        uploadOption = {
            ...uploadOption,
            onUploadProgress: option.progressCallback
        }
    }

    return postRequest<string>('/uploadPhoto', uploadOption);
}