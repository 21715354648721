import { Box } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BoxPlotContainer from "../components/BoxPlotContainer/BoxPlotContainer";
import ChartContainer from "../components/ChartContainer/ChartContainer";
import DecompositionContainer from "../components/DecompositionContainer/DecompositionContainer";
import ScenariosContainer from "../components/ScenariosContainer/ScenariosContainer";
import SignificanceContainer from "../components/SignificanceContainer/SignificanceContainer";
import { RootState } from "../store/store";
import styles from './Charts.module.scss';
import { init as colorStoreInit } from "../store/colorStore";
import { 
	init as chartStoreInit,
	setColors as chartSetColors
} from "../store/chartStore";
import { 
	init as statesStoreInit, 
	setColors as stateSetColors
} from "../store/statesStore";
import SoeContainer from "../components/SoeContainer/SoeContainer";
import { GridType } from "../../pages/Results/Results";
import { CalculatePyload } from "../../libs/services/calculate.service";
import { ChartType } from "../../libs/const/chartType.const";

interface ChartsProps {
    gridType: GridType,
    data: any,
    onUpdate: (option: CalculatePyload) => void
    displayChartList: Array<ChartType>
}

const Charts: FC<ChartsProps> = (props) => {

    const dispatch = useDispatch();

	const [color, setColor] = useState([]);

    const [result, setResult] = useState(null);

	const newColor = useSelector((state: RootState) => state.colorStore.colorArr);
    
	useEffect(() => {
		setColor(newColor);
		dispatch(stateSetColors({colors: newColor}));
		dispatch(chartSetColors({colors: newColor}));
	});
    
    useEffect(() => {

        if (props.data) {
            dispatch(colorStoreInit({response: props.data}));
        
            dispatch(statesStoreInit({
                response: props.data,
                colors: color
            }))
        
            var graph = props.data.graph;
        
            dispatch(
                chartStoreInit({
                    min: graph.xAxis.min, 
                    max: graph.xAxis.max, 
                    step: graph.xAxis.step, 
                    precission: graph.xAxis.precission, 
                    values: graph.percentValues,
                    colors: color
                })
            );  
    
            setResult(props.data);
        }

    }, [props.data && JSON.stringify(props.data) === JSON.stringify(result)])

    return (
        <Box
            style={{                
                gridTemplateColumns: props.gridType === GridType.COLOMN ? `repeat(1, minmax(0, 1fr))` : `repeat(2, minmax(0, 0.5fr))`
            }}
            className={styles.ChartContainer}>
            <>
                {
                    props.displayChartList.includes(ChartType.HISTOGRAM) &&
                    <Box className={styles.chartItem}>
                        <ChartContainer/>
                    </Box>
                }
            </>
            <>
                {
                    props.displayChartList.includes(ChartType.BOXPLOTS) &&
                    <Box className={styles.chartItem}>
                        <BoxPlotContainer/>
                    </Box>
                }
            </>
            <Box className={styles.chartItem}>
                <ScenariosContainer  gridType={props.gridType}/>
            </Box>
            <>
                {
                    props.displayChartList.includes(ChartType.DECOMPOSITION) &&                    
                    <Box className={styles.chartItem}>
                        <DecompositionContainer onUpdate={props.onUpdate} />
                    </Box>
                }
            </>
            <>
                {
                    props.displayChartList.includes(ChartType.SIGNIFANCE) && 
                    <Box className={styles.chartItem}>
                        <SignificanceContainer />
                        <SoeContainer />
                    </Box>
                }
            </>
        </Box>
    );
}

export default Charts;