import { FC } from 'react';
import styles from './DataTable.module.scss';
import { DataGrid, GridCallbackDetails, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import {Box, SelectChangeEvent} from "@mui/material";
import './DataTable.scss';
import CustomPagination from '../CustomPagination/CustomPagination';
import { FileInfo } from '../../libs/const/fileInfo.const';
import { DataFilter } from '../../libs/services/session.service';
import SortComponent from '../SortComponent/SortComponent';

export enum DataTableStyles {
    BaseStyle = "data-table",
    Border = "data-table-border",
    TopHeaderBorder = "data-table-top-header-border",
    BottomHeaderBorder = "data-table-bottom-header-border",
    HighRow = "table-high-row",
    HeaderCell = "data-table-header-cell"
}

export interface DataTableSorting {
    field: string, 
    headerName: string,
}

export interface DataTableState {
    rows: Array<any>;
    filter: DataFilter;
    totalRows: number;
    loading: boolean;
    rowId: string | null;
}

interface DataTableProps {
    tableState: DataTableState
    onSorting?: (event: SelectChangeEvent<any>, child: React.ReactNode) => void,
    onFilter: (filter: DataFilter) => void,
    columns: GridColDef[],
    sorting?: Array<DataTableSorting>,
    onRowClick?: (params: GridRowParams<FileInfo>, event: MuiEvent<React.MouseEvent>) => void,
    customClasses?: string
}

const DataTable: FC<DataTableProps> = (props) => {
    let className = `${DataTableStyles.BaseStyle}`;
    if (props.customClasses) {
        className+=` ${props.customClasses}`;
    }
    
    return (
        <Box className={className}>
            <div className={styles.filter}>
                {
                    props.sorting && <div className={styles.sortBy}>
                    Sort by
                    <SortComponent 
                        sorting={props.sorting} 
                        filter={props.tableState.filter}
                        onFilter={props.onFilter}/>
                    </div>
                }
            </div>
            <div className={styles.table}>
                <DataGrid 
                    autoHeight={true}                    
                    isRowSelectable={() => false}
                    rows={props.tableState.rows}
                    columns={props.columns}
                    pageSize={10}
                    rowCount={props.tableState.totalRows}
                    rowsPerPageOptions={[10]}
                    disableColumnSelector={true}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    paginationMode="server"
                    page={props.tableState.filter.pageNumber-1}
                    headerHeight={43}
                    onPageChange={(pageNumber) => props.onFilter({...props.tableState.filter, pageNumber: pageNumber+1 })}
                    onRowClick={
                        (params: GridRowParams, 
                        event: MuiEvent<React.MouseEvent>, 
                        details: GridCallbackDetails) => {
                            if (props.onRowClick){
                                props.onRowClick(params, event)
                            }
                        }}
                    localeText={{
                      footerRowSelected: CustomPagination
                    }}
                    components = {{
                        Pagination: CustomPagination
                    }}
                    classes={{
                        virtualScrollerContent: props.tableState.rows.length > 0 ?
                                                    styles.virtualScrollerContent : 
                                                    styles.virtualScrollerContentEmpty ,
                        virtualScrollerRenderZone:styles.virtualScrollerRenderZone,
                    }}
                />
            </div>
        </Box>
    );
}

export default DataTable;
