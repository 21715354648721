import { UserData } from "../models/userData.model";
import { formatBytes } from "../services/bytes.service";

const CALCULATION_KEY = 'calculationCount';
const STORAGE_KEY = 'storageBytes';

export function isLimitationError(actual: number, max: number) {
    return actual === max;
}

export function isLimitationWarning(actual: number, max: number) {
    return ((actual*100)/max) >= 80;
}

export function isLimitationErrorFromUser(user: UserData, key: string) {
    return isLimitationError(
        (user?.licensingInfo as any)?.actualMeasure?.[key],
        (user?.licensingInfo as any)?.plan?.measure?.[key],
    )
}

export function isLimitationErrorCalculationFromUser (user: UserData) {
    return isLimitationErrorFromUser(user, CALCULATION_KEY);
}

export function isLimitationErrorStorageFromUser (user: UserData) {
    return isLimitationErrorFromUser(user, STORAGE_KEY);
}

export function isLimitationErrorByUser(user: UserData) {
    return isLimitationErrorCalculationFromUser(user) || 
        isLimitationErrorStorageFromUser(user);
}

export function isLimitationWarningFromUser(user: UserData, key: string) {
    return isLimitationError(
        (user?.licensingInfo as any)?.actualMeasure?.[key],
        (user?.licensingInfo as any)?.plan?.measure?.[key],
    )
}

export function isLimitationWarningCalculationFromUser (user: UserData) {
    return isLimitationErrorFromUser(user, CALCULATION_KEY);
}

export function isLimitationWarningStorageFromUser (user: UserData) {
    return isLimitationErrorFromUser(user, STORAGE_KEY);
}

function generateErrorCalculationMessage(user: UserData) {
   return `You have used out ${(user?.licensingInfo as any)?.actualMeasure?.[CALCULATION_KEY]} of ${(user?.licensingInfo as any)?.plan?.measure?.[CALCULATION_KEY]} transactions.`
}

function generateErrorStorageMessage(user: UserData) {
   return `You have used ${formatBytes((user?.licensingInfo as any)?.actualMeasure?.[STORAGE_KEY])} out of ${formatBytes((user?.licensingInfo as any)?.plan?.measure?.[STORAGE_KEY])}.`;
}

export function getErrorMessages(user: UserData) {
    let msg = 'Limits exceeded:';
    if (isLimitationErrorCalculationFromUser(user)) {
        msg = msg ? 
            `${msg}\n${generateErrorCalculationMessage(user)}` :
            generateErrorCalculationMessage(user);
    } 
    if (isLimitationErrorStorageFromUser(user)) {
        msg = msg ? 
            `${msg}\n${generateErrorStorageMessage(user)}` :
            generateErrorStorageMessage(user);
    }
    return msg;
}