import { LinearProgress } from "@mui/material";
import { FC } from "react";
import { formatBytes } from "../../../libs/services/bytes.service";
import { isLimitationError, isLimitationWarning } from "../../../libs/utils/Limitation";

import styles from './TransactionsPropgress.module.scss'; 

interface TransactionsProgressProps {
    isByte?: boolean,
    applay: number,
    count: number,
    title: string,
    wrapperClassName?: string
}

const CIRCLE_WIDTH = 19;

const TransactionsProgress: FC<TransactionsProgressProps> = (props) => {    

    const classes = {
        colorPrimary: styles.colorPrimary,
        barColorPrimary: styles.barColorPrimary,
        root: styles.progressRoot
    }

    const apllayPercent = (props.applay*100)/props.count;

    const circlePositionStyle = {
      left: `calc(${apllayPercent}% - ${CIRCLE_WIDTH/2}px)`,     
      width: CIRCLE_WIDTH
    }

    return (        
      <div className={`
        ${styles.transactionsPropgress}
        ${props.wrapperClassName}
        ${isLimitationError(props.applay, props.count) ? styles.error : ''}
        ${isLimitationWarning(props.applay, props.count) ? styles.warning : ''}
      `}>
        <div className={styles.title}>
          <div className={styles.name}>
            {props.title}
          </div>
          <div className={styles.limitation}>
            {
              props.isByte ? 
                <><span>{formatBytes(props.applay)}</span> of <span>{formatBytes(props.count)}</span></> : 
                <><span>{props.applay}</span> of <span>{props.count}</span></>
            }
          </div>
        </div>
        <div className={styles.progressBarr}>
          <div 
            style={circlePositionStyle}
            className={styles.circle}>
            <div></div>
          </div>
          <LinearProgress classes={classes} variant="determinate" value={apllayPercent} />
        </div>
      </div>
    )
}

export default TransactionsProgress;