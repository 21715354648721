import { getRequest, postRequest } from "./http.service";
import {CalculationResultData} from "../models/calculationResult.model";

export interface CalculatePyload {
    sessionId?: string | number,
    graphBinsCount?: number,
    decomposition?: any,
    columns?: any,
    isHeadersRowPresented?: boolean
}

export async function calculate(payload: CalculatePyload, abortController: AbortController) {
    return postRequest<CalculationResultData>("/calculate", {
        data: payload,
        signal: abortController.signal
    });
}

export async function getResult(sessionId: string, resultId: any) {    
    return getRequest<any>("/getResult", {
        params: {
            sessionId,
        }
    });
}
