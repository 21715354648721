import { AxiosRequestConfig } from "axios";
import { FileInfo } from "../const/fileInfo.const";
import {deleteRequest, getRequest, postRequest} from "./http.service";

export async function startSession(option?: AxiosRequestConfig<null>) {
    return await getRequest<string>("/startSession", option);
}

export interface DataFilter {
    sortField: string,
    pageNumber: number,
    pageSize: number,
    ascending?: boolean
}

export interface GetSessionResponse {
    items: Array<FileInfo>,
    totalItemsCount: number
}

export async function getSessions(option: AxiosRequestConfig<DataFilter>) {
    return await postRequest<GetSessionResponse>('/getSessions', option);
}

export async function getSession(sessionId: string, option?: AxiosRequestConfig<null>) {
    const params = {
        sessionId
    };

    let getOption:AxiosRequestConfig  = {
        params
    };

    return await getRequest<FileInfo>("/getSession", getOption);
}

export async function deleteSession(sessionId: string, option?: AxiosRequestConfig<null>) {
    const params = {
        sessionId
    };

    let deleteOption:AxiosRequestConfig  = {
        params
    };
    
    return await deleteRequest<string>("/closeSession", deleteOption);
}
