import React, {FC, useEffect, useState} from 'react';
import styles from './SignOut.module.scss';
import {UserData} from "../../libs/models/userData.model";
import {useMsal} from "@azure/msal-react";
import {getUser} from "../../libs/services/user.service";
import {CurrentUser} from "../../libs/observables/CurrentUser";
import UserInfo from "../UserInfo/UserInfo";

interface SignOutProps {
    showDescription?: boolean
}

const SignOut: FC<SignOutProps> = (props) => {
    const [user, setUser] = useState<UserData>();
    const {instance} = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    useEffect(() => {
        if (!user) {
            getUser().then((response) => {
                CurrentUser.currentUser$.next(response.data);
            });
        }
    }, [user?.id]);

    useEffect(() => {
        const subscription = CurrentUser.currentUser$.subscribe((value) => {
            setUser(value);
        });
        return () => subscription.unsubscribe();
    });

    return (
        <div className={styles.SignOut}>
            <UserInfo showDescription={props.showDescription} user={user} small={false}/>
            <img className={styles.img} alt="" src="/images/signOut.svg" onClick={handleLogout}/>
        </div>
    )
};

export default SignOut;
