import React, { FC } from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import styles from './ArchiveMenuComponent.module.scss';
import { FileInfo } from '../../libs/const/fileInfo.const';
import DropDownMenu from '../DropDownMenu/DropDownMenu';

export interface ActionItem<T> {
    action: (data:T) => void,
    data: T,
    actionName: string
}

export const ArchiveMenuComponentId = 'basic-button';

interface ArchiveMenuComponentProps<T> {
    actionList: Array<ActionItem<T>>
}

const ArchiveMenuComponent: FC<ArchiveMenuComponentProps<FileInfo>> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const itemClick = (item: ActionItem<FileInfo>) => {
        handleClose();
        item.action(item.data)
    }
    
    return (
        <div className={styles.ArchiveMenuComponent}>
            <Button
                id={ArchiveMenuComponentId}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                ...
            </Button>
            <DropDownMenu 
                handleClose={handleClose} 
                anchorEl={anchorEl} 
                open={open} 
                id={'basic-menu'}>
                {
                    props.actionList.map(item => 
                        <MenuItem 
                            key={item.actionName} 
                            onClick={() => itemClick(item)}>
                                {item.actionName}
                        </MenuItem>
                    )
                }
            </DropDownMenu>
        </div>
    );
}

export default ArchiveMenuComponent;
