import React, {FC, useEffect} from 'react';
import styles from './AccountLayout.module.scss';
import Box from "@mui/material/Box";
import MuiTabs from '@mui/material/Tabs';
import {Tab} from "@mui/material";
import {styled} from "@mui/material/styles";
import ACCOUNT_ROUTE from '../AccountRoute';
import { useHistory, useLocation } from 'react-router-dom';

interface AccountSettingsComponentProps {}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div 
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className={styles.content}>
                        {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StyledTabs = styled(MuiTabs)(
    ({ theme }) => ({
        '& .MuiTabs-indicator': {
            display: 'none',
        },
        '& .Mui-selected': {
            backgroundColor: '#EAF5FE',
        }, 
        '& .MuiButtonBase-root': {
            marginBottom: '10px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '21px',
            paddingLeft: '27px',
            paddingRight: '27px',
            paddingTop: '10px',
            paddingBottom: '10px'
        },
        '& .MuiTabs-flexContainer': {
            minWidth: 'fit-content',
            overflow: 'auto'
        }
    }),
);

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AccountSettingsComponent: FC<AccountSettingsComponentProps> = (props) => {

    const [value, setValue] = React.useState(0);

    const history = useHistory();

    const setProps = (tab: string | null) => 
        history.push({
            search: `?tab=${tab}`
        });

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setProps(ACCOUNT_ROUTE[newValue].tab);
    };

    
    let query = useQuery();

    useEffect(() => {
        if (query.get('tab')) {
            setProps(query.get('tab'));
            setValue(ACCOUNT_ROUTE.findIndex(item => item.tab === query.get('tab')))
        } else {
            setProps(ACCOUNT_ROUTE[0].tab);
            setValue(0);
        }
    }, [query.get('tab')])
    
    return (
        <div className={styles.root}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {
                        ACCOUNT_ROUTE.map((item, index) => (
                            <Tab key={item.tab} className={styles.tabItem} label={item.label} {...a11yProps(index)} />
                        ))
                    }
                </StyledTabs>
            </Box>
            {
                ACCOUNT_ROUTE.map((item, index) => (
                    <TabPanel  key={item.tab} value={value} index={index}>
                        <item.component/>
                    </TabPanel>
                ))
            }
        </div>
    );
}

export default AccountSettingsComponent;
