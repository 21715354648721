import { createSlice } from '@reduxjs/toolkit';

export const useDataStore = createSlice({
	name: 'data',
  initialState: {
    items: {
    	inputs: [],
    	outputs: []
    },
  },
  reducers: {
    addData: (state, action /*range, getProp, dataArr*/) => {
      const range = action.payload.range;
      const getProp = action.payload.getProp;
      const dataArr = action.payload.dataArr;
    	range.values.forEach((item: any, row: any) => {
    		dataArr.push({
    			id: dataArr.length + 1,
    			name: item[0],
  				value: item[1],
    			nameCell: getProp.value[row][0].address,
    			valueCell: getProp.value[row][1].address,
    			dist: 'uni'
  			});
  			row++;
    	})
		},
    removeData: (state, action) => {
      const dataArr = action.payload.dataArr;
      const id = action.payload.id;
    	var newId = 0;
  		dataArr.splice(id - 1, 1);
    	dataArr.forEach((item: any) => item.id = ++newId)
  	},
    updateData: (state, action) => {
      const dataArr = action.payload.dataArr;
      const id = action.payload.id;
      const arg = action.payload.arg;
      const rangeCell = action.payload.rangeCell;
      const rangeVal = action.payload.rangeVal;
			dataArr.forEach((elem: any) => {
    		if (elem.id === id) {
    			elem[arg] = rangeVal;
    			if (arg === 'name') {
    				elem.name = rangeVal;
    			} else {
    				elem[`${arg}Cell`] = rangeCell;
					}
    		}
    	})
    },
    updateId: (state, action) => {
    	state.items.inputs.forEach((elem: any, index) => {
			  elem.id = index + 1;
    	})
  		state.items.outputs.forEach((elem: any, index) => {
    		elem.id = index + 1;
    	})
    }
  }
});

export const { addData, removeData, updateData, updateId } = useDataStore.actions;

export default useDataStore.reducer;
