import red from '@mui/material/colors/red';
import styles from './constants.module.scss';
import { createTheme } from '@mui/material';

// Create a theme instance.
export const theme = createTheme({
  palette: {
    primary: {
      main: styles.colorPrimary,
    },
    secondary: {
      main: '#EAF5FE',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: "\"Poppins\", sans-serif;",
  }
});
