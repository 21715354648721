import { Box, Button } from "@mui/material";
import { FC } from "react";
import styles from './EmailContactCard.module.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Plan, PlanType, PLAN_DESCRIPTION } from "../../../libs/models/licensingData.model";
import {formatBytes} from "../../../libs/services/bytes.service";

interface EmailContactCardProps {
  email: string;
}

const EmailContactCard: FC<EmailContactCardProps> = (props) => {
    
    return (
      <Box className={`${styles.card} ${styles.activeCard}`}>
        <Box className={styles.header}>
          <Box className={styles.headerTitle}>
            <img src='/images/icon-logo.icon.svg' />
          </Box>
          <Box className={styles.description}>
            <Box className={styles.title}>
              Contact us for more options:
            </Box>
            {props.email}
          </Box>
        </Box>
      </Box>
    )
}

export default EmailContactCard;