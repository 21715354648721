import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export const useColorSlice = createSlice({
  name: 'color',
  initialState:{
    colorArr: [],
    metadata: null
  },
  reducers: {
    init: (state, action) => {
      const response = action.payload.response;
      let mainColors = [[0, 176, 240], [231, 216, 25], [31, 223, 77], [255, 0, 0], [128, 128, 128]];
      (state.metadata as any) = response.metadata;
        
      let nMainColors = response.metadata[response.scenarios[0].requirements[0].index].states.length;
      let nShades = response.scenarios.length / nMainColors;
        
      let step = Math.min(0.5, 0.8 / (nShades - 1));
        
      let base = 0.4;
      
      for (let i = 0, k = 0; i < nMainColors; i++) {
        for (let j = 0; j < nShades; j++, k++) {
          let multiplayer = base + j * step;
          (state.colorArr as any)[k] = "#";
          for (let c = 0; c < mainColors[i].length; c++) {
            let hex = Math.min(255, Math.round(mainColors[i][c] * multiplayer)).toString(16);
        
            if (hex.length < 2) {
              hex = "0" + hex;
            }
        
            (state.colorArr as any)[k] += hex;
          }
        }
      }
    },
    setColor: (state, action) => {
      (state.colorArr as any)[action.payload.scIndex] = action.payload.color;
    }
  }
});

export const { init, setColor } = useColorSlice.actions;

export default useColorSlice.reducer;

export const chartData = (state: RootState) => {
  return state.colorStore.colorArr;
};