export const HOME_MENU_PATH = '/home';

export const menus = [
    {
        description: 'Home', 
        iconPath: "/images/home.icon.svg", 
        href: '/home',
    },
    {
        description: 'Analysis results archive', 
        iconPath: "/images/analysis.icon.svg", 
        href: '/analysis',
    },
    {
        description: 'Account settings', 
        iconPath: "/images/user.icon.svg", 
        href: '/account',
    }
];