import { GridColDef, GridRowParams, GridValueFormatterParams, MuiEvent } from "@mui/x-data-grid";
import React from "react";
import { Redirect } from "react-router-dom";
import ArchiveMenuComponent, { ActionItem, ArchiveMenuComponentId } from "../../components/ArchiveMenuComponent/ArchiveMenuComponent";
import { FileInfo } from "../../libs/const/fileInfo.const";
import { FileStatus } from "../../libs/const/fileStatus.const";
import {getSessions, DataFilter, deleteSession} from "../../libs/services/session.service";
import styles from "./Analysis.module.scss";
import DataTable, {DataTableState, DataTableStyles} from "../../components/DataTable/DataTable";
import {formatBytes} from "../../libs/services/bytes.service";
import { LinearProgress } from "@mui/material";
import {hideAlert, hideProgress, showProgress, showWarningAlert} from "../../account/components/alerts/alert.service";


const DEFAUL_ANALYSIS_STATE:DataTableState = {
    rows: [],
    filter: {
        sortField:'createDate',
        pageNumber: 1,
        pageSize: 10,
        ascending: false
    }, 
    totalRows: 0,
    loading: false,
    rowId: null
}

export default class Analysis extends React.Component<any, DataTableState> {
    constructor(props: any) {        
        super(props);
        this.state = DEFAUL_ANALYSIS_STATE;
        this.filterChange = this.filterChange.bind(this);
        this.tableRowClick = this.tableRowClick.bind(this);
    }

    private tableRowClick(params: GridRowParams<FileInfo>, event: MuiEvent<React.MouseEvent>) {
        if ((event.target as HTMLElement).id !== ArchiveMenuComponentId) {
            this.setState({rowId: params.row.id});
        }
    }

    private abortController: AbortController = new AbortController();   

    private sorting = [
        {
            field: 'fileName', 
            headerName: 'Name',
        },
        {
            field: 'createDate', 
            headerName: 'Date', 
        },
        {
            field: 'state',
            headerName: 'Status', 
        }
    ];
    
    private columns: GridColDef[] = [
        { 
            field: 'fileName', 
            headerName: 'Name', 
            flex: 1, 
            sortable: false,
            headerClassName: DataTableStyles.HeaderCell,
        },
        {
            field: 'fileSize',
            headerName: 'Size',
            flex: 1,
            sortable: false,
            headerClassName: DataTableStyles.HeaderCell,
            valueFormatter: (params: GridValueFormatterParams<number>) =>
                formatBytes(params.value)
        },
        { 
            field: 'createDate', 
            headerName: 'Date', 
            flex: 1, 
            sortable: false,
            headerClassName: DataTableStyles.HeaderCell,
            valueFormatter: (params: GridValueFormatterParams<FileStatus>) => 
                new Date(params.value).toLocaleDateString('en-us', { month:"short", year:"numeric", day: 'numeric',})
        },
        { 
            field: 'state',
            headerName: 'Status', 
            flex: 0., 
            sortable: false,
            headerClassName: DataTableStyles.HeaderCell,
            valueFormatter: (params: GridValueFormatterParams<FileStatus>) => FileStatus[params.value]
        },
        {
            field: "action",
            headerName: "",
            sortable: false,
            flex: 0.2,
            headerClassName: DataTableStyles.HeaderCell,
            renderCell: (params) => {
                const actionList: Array<ActionItem<FileInfo>> = [
                    {
                        data: params.row,
                        actionName: 'Delete',
                        action: async (data: FileInfo) => {
                            showWarningAlert({
                                title: 'Confirmation',
                                children: `Are you sure to delete all data for ${data.fileName}`,
                                isAlert: false,
                                onAction: () => {
                                    hideAlert();
                                    showProgress();
                                    deleteSession(data.id).then(async () => {
                                        this.loadAnalisys(this.state.filter);
                                    }).finally(() => hideProgress())
                                }
                            })
                        } 
                    },
                    {
                        data: params.row,
                        actionName: 'Show',
                        action: (data: FileInfo) => this.setState({rowId: data.id})
                    }
                ];
                return <ArchiveMenuComponent actionList={actionList}/>;
            }
        },
    ]

    abortRequest () {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }
    }

    filterChange(filter: DataFilter) {
        this.setState({filter});
    }

    componentDidMount() {
        this.loadAnalisys(this.state.filter);
    }

    componentDidUpdate(prevProps: any, prevState: DataTableState) {
        if (
            this.state.filter.pageNumber !== prevState.filter.pageNumber ||
            this.state.filter.pageSize !== prevState.filter.pageSize ||
            this.state.filter.sortField !== prevState.filter.sortField ||
            this.state.filter.ascending !== prevState.filter.ascending
        ) {
            this.loadAnalisys(this.state.filter);
        }
    }

    loadAnalisys(filter: DataFilter) {
        this.abortRequest();
        this.setState({loading: true});
        getSessions({
            data: filter,
            signal: this.abortController.signal
        }).then((response) => this.setState({
            rows: response.data.items,
            totalRows: response.data.totalItemsCount
        })).finally(() => this.setState({
            loading: false
        }))
    }

    render() {
        return (
            <>
                {
                    this.state.rowId ? 
                    <Redirect  to={`/analysis/${this.state.rowId}`}/> :
                    <div className={styles.ArchiveComponent}>
                        <div className={styles.title}>Analysis results archive</div>
                        {
                            this.state.loading && <LinearProgress />
                        }
                        <DataTable
                            tableState={this.state}
                            onRowClick={this.tableRowClick}
                            columns={this.columns}
                            onFilter={this.filterChange}
                            sorting={this.sorting}
                            customClasses={`${DataTableStyles.TopHeaderBorder}`}
                        />
                    </div>
                }
            </>
        );
    }
}