import { createSlice, current } from '@reduxjs/toolkit';
import { RootState } from './store';

export const useStatesStore = createSlice({
  name: 'states',
  initialState:{
    metadata: null,
    selectedColumns: [],
    scenarios: [],
    colors: [],
    isAutomatic: true,
    selectedIndex: null,
    //
    requirements: null
  },
  reducers: {
    init: (state, action) => {
      const response = action.payload.response;
      const colors = action.payload.colors;
      state.colors = colors;
      if (state.isAutomatic) {
        state.metadata = response.metadata;
      } else {
        for (let i = 0; i < response.metadata.length; i++) {
          (state.metadata as any)[i].states = response.metadata[i].states;
          (state.metadata as any)[i].orderedCategories = response.metadata[i].orderedCategories;
        }
      }
    
      state.scenarios = response.scenarios;

      let requirements = (state.scenarios[0] as any).requirements;
    
      if (state.isAutomatic) {
        state.selectedColumns = [];
      }
    
      for (let i = 0; i < requirements.length; i++) {
        let metadata = (state.metadata as any)[requirements[i].index];
    
        (state.selectedColumns as any)[i] =
          {
            states: metadata.states,
            index: metadata.index,
            name: metadata.name,
            categories: metadata.orderedCategories,
            order: metadata.totalOrderEffect
          };
      }
    },
    addInput: (state, action) => {
      const metadata = current(state.metadata);
      const metadataItem = (metadata as any)[action.payload.index];
      (state.selectedColumns as any).push({
        states: metadataItem.states,
        index: metadataItem.index,
        name: metadataItem.name,
        categories: metadataItem.orderedCategories
      });
    },
    removeInput: (state, action) => {
      state.selectedColumns.splice(action.payload.index, 1);
    },
    addState: (state, action) => {
      const inputIndex = action.payload.inputIndex;
      const selectedColumns = current(state.selectedColumns);
      let lastState = (selectedColumns[inputIndex] as any).states[(selectedColumns as any)[inputIndex].states.length - 1];
      const metadata = current(state.metadata);
      let meta = (metadata as any)[(selectedColumns[inputIndex] as any).index];
      
      if (meta.isCategorical && (lastState.max - lastState.min) <= 0) {
        return;
      }
      
      let av = (lastState.min + lastState.max) / 2;
      
      if (meta.isCategorical) {
        av = Math.round(av);
      }
      
      (state.selectedColumns[inputIndex] as any).states.push({
        min: av,
        max: lastState.max,
        type: "State " + (state.selectedColumns[inputIndex] as any).states.length
      });
      
      (state.selectedColumns[inputIndex] as any)
        .states[(state.selectedColumns[inputIndex] as any).states.length - 2]
        .max = meta.isCategorical ? av - 1 : av;
    },
    removeState: (state, action) => {
      const inputIndex = action.payload.inputIndex;
      const stateIndex = action.payload.stateIndex;
      if ((state.selectedColumns[inputIndex] as any).states.length < 2) {
        return;
      }
    
      if (stateIndex === 0) {
        (state.selectedColumns[inputIndex] as any).states[stateIndex + 1].min = (state.selectedColumns[inputIndex] as any).states[stateIndex].min;
      } else {
        (state.selectedColumns[inputIndex] as any).states[stateIndex - 1].max = (state.selectedColumns[inputIndex] as any).states[stateIndex].max;
      }
    
      (state.selectedColumns[inputIndex] as any).states.splice(stateIndex, 1);
    },
    onChange: (state, action) => {
      const inputIndex = action.payload.inputIndex;
      const index = action.payload.index;
      const value = action.payload.value;
      let val = +value;
      let meta = (state.metadata as any)[(state.selectedColumns[inputIndex] as any).index];
      if (meta.isCategorical && val !== Math.round(val)) {
        return;
      }
    
      var nextMin = meta.isCategorical ? val + 1 : val;
    
      if (
        (state.selectedColumns[inputIndex] as any).states[index + 1].max <= nextMin
        || (meta.isCategorical && val !== Math.round(val))
        || val < (state.selectedColumns[inputIndex] as any).states[index].min
      ) {
        return;
      }
    
      (state.selectedColumns[inputIndex] as any).states[index].max = value;
      (state.selectedColumns[inputIndex] as any).states[index + 1].min = nextMin;
    },
    onTypeChange: (state, action) => {
      const inputIndex = action.payload.inputIndex;
      const index = action.payload.index;

      (state.selectedColumns[inputIndex] as any).states[index].type = action.payload.value;            
    },
    
    downCategory: (state, action /*inputIndex, index*/) => {
      const inputIndex = action.payload.inputIndex;
      const index = action.payload.index;
      var tmp = (state.selectedColumns[inputIndex] as any).categories[index];
      (state.selectedColumns[inputIndex] as any).categories[index] = (state.selectedColumns[inputIndex] as any).categories[index + 1];
      (state.selectedColumns[inputIndex] as any).categories[index + 1] = tmp;
    },
    upCategory: (state, action) => {
      const inputIndex = action.payload.inputIndex;
      const index = action.payload.index;
      // this.downCategory(inputIndex, --index);
      let tmp = (state.selectedColumns[inputIndex] as any).categories[index];
      (state.selectedColumns[inputIndex] as any).categories[index] = (state.selectedColumns[inputIndex] as any).categories[index + 1];
      (state.selectedColumns[inputIndex] as any).categories[index + 1] = tmp;
    },
    setColor: (state, action) => {
      (state.colors as any)[action.payload.scIndex] = action.payload.color;
    },
    setColors: (state, action) => {
      state.colors = action.payload.colors;
    },
    setSelectedIndex: (state, action) => {
      state.selectedIndex = action.payload.selectedIndex;
    },
    setIsAutomatic: (state, action) => {
      state.isAutomatic = action.payload.isAutomatic;
    }
  }
});

export const toReadable = (value: number) => {
  let abs = Math.abs(value);

  let i = 0;
  for (; i < 3 && abs < 100; i++) {
    abs *= 10;
  }

  return Math.round(value * Math.pow(10, i)) / Math.pow(10, i);
}

export const { 
  init, 
  addInput, 
  removeInput, 
  addState, 
  removeState,
  onChange, 
  onTypeChange, 
  upCategory, 
  setColor,
  setColors,
  setSelectedIndex,
  setIsAutomatic,
  downCategory
} = useStatesStore.actions;

export default useStatesStore.reducer;

// -----------------------------------------------------------------

export const selectorSelectedInputs = (state: RootState) => {
  return state.statesStore.selectedColumns;
};

export const selectScenariosData = (state: RootState) => {
  const statesStore = state.statesStore
  if (statesStore.scenarios.length === 0) {
    return [];
  }
  const scenarios = JSON.parse(JSON.stringify(statesStore.scenarios));
  let requirementsWeighted = selectRequirements(state);

  for (let i = 0; i < statesStore.scenarios.length; i++) {
    let k = 0;
    if (!scenarios[i]) {
      (scenarios[i] as any) = {
        requirementsWeighted: [],
        color: statesStore.colors[i]
      };
    } else {
      (scenarios[i] as any).requirementsWeighted = [];
      (scenarios[i] as any).color = statesStore.colors[i];
    }

    for (let j = 0; j < (scenarios[i] as any).requirements.length; j++) {
                
      if ((i % (requirementsWeighted as any)?.[j]?.weight) === 0) {
        (scenarios[i] as any).requirementsWeighted[k++] = {
          data: (scenarios[i] as any).requirements[j],
          weight: (requirementsWeighted as any)[j].weight
        }
      }
    }
  }

  return scenarios;
}

export const selectRequirements = (rootState: RootState) => {
  const state = rootState.statesStore;
  let requirements = [];
  let weight = 1;

  for (let i = (state.scenarios[0] as any)?.requirements?.length - 1; i >= 0; i--) {
    requirements[i] = {
      weight: weight,
      name: (state.metadata as any)[(state.scenarios[0] as any).requirements[i].index].name
    };
    weight *= (state.metadata as any)[(state.scenarios[0] as any).requirements[i].index].states.length;
  }

  return requirements;
};

export const selectSignificance = (rootState: RootState) => {
  const state = rootState.statesStore;
  if (state.metadata == null) {
    return [];
  }

  let significance = []
  let total = 0;
  for (let i = 1; i < (state.metadata as any).length; i++) {
    significance[i - 1] = {
      name: (state.metadata[i] as any).name,
      value: (state.metadata[i] as any).totalOrderEffect
    }
    total += (state.metadata[i] as any).totalOrderEffect
  }
  for (let i = 0; i < significance.length; i++) {
    significance[i].value = Math.round(significance[i].value * 100 / total);
  }
  const valueList = significance.map(item => item.value);
  const valueMax = Math.max.apply(null, valueList);
  for (let i = 0; i < significance.length; i++) {
    (significance[i] as any).relativeValue = (significance[i].value * 100) / (valueMax + 10);
  }

  significance.sort((a, b) => b.value - a.value);

  return significance;
}


export const selectAvailableInputsWithAdd = (rootState: RootState) => {
  const state = rootState.statesStore
  let inputs = [];
  let selectedIndex : number | null = state.selectedIndex;

  if (state.metadata != null) {
    for (let i = 1; i < (state.metadata as any).length; i++) {
      let add = true;

      for (let j = 0; j < state.selectedColumns.length; j++) {
        if ((state.selectedColumns[j] as any).index === i) {
          add = false;
        }
      }
      if (add) {
        inputs.push({ value: i, text: (state.metadata[i] as any).name });
      } else {
        if (i == selectedIndex) {
          selectedIndex = null;
        }
      }
    }
  }
  if (selectedIndex === null && inputs.length) {
    selectedIndex = inputs[0].value;
  } 
  return {inputs, selectedIndex};
};

export const selectSelectedIndex =(rootState: RootState) => 
  selectAvailableInputsWithAdd(rootState).selectedIndex;

export const selectAvailableInputs = (rootState: RootState) => 
  selectAvailableInputsWithAdd(rootState).inputs;
        
export const selectSoeEffects = (rootState: RootState) => {
  const state = rootState.statesStore
  let soe = [];

  if (state.metadata != null) {
    for (let i = 1; i < (state.metadata as any).length; i++) {
      soe[i - 1] = {
        name: (state.metadata[i] as any).name,
        soe: (state.metadata[i] as any).secondOrderEffects
      }
    }
  }

  return soe;
};
        
export const boxPlotOptions = (rootState: RootState) => {
  return {
    chart: {
      type: 'boxPlot',
      height: 'auto',
    },
    title: {
      text: "Distribution of the output",
      align: 'left'
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%'
      },
    },
    xaxis: {
      title: {
        text: 'Output'
      }
    },
    stroke: {      
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: ['#000000'],
      width: 2,
      dashArray: 0,    
    },
    colors: []
  }
}
        
export const boxPlotSeries = (rootStates: RootState) => {
  const state = rootStates.statesStore;
  let data = [];

  for (let i = 0; i < state.scenarios.length; i++) {
    let sc: any = state.scenarios[i];
    data[i] = {
      x: "sc_" + (i + 1),
      y: [sc.min, sc.quartiles[0], sc.quartiles[1], sc.quartiles[2], sc.max]
    }
  }

  return [
    {
      data: data
    }
  ];
};