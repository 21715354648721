import { apiConfig, loginRequest } from "../../authConfig";
import { AxiosError, AxiosRequestConfig } from "axios";
import axios from "axios";
import { showErrorAlert } from "../../account/components/alerts/alert.service";
import { AuthenticationResult } from "@azure/msal-browser";
import { msalInstance } from "../../App";

interface Response<T> {
    data: T;
    status: number;
    statusText: string;
}

export const getRequest = async <D>(url: string, options?: AxiosRequestConfig): Promise<Response<D>> => {
    options = {
        ...options,
        baseURL: apiConfig.uri,
        method: 'GET'
    }
    options = await addAuthorizationHeader<D>(options);
    return await applyRequest(url, options);
};
    
export const postRequest = async <D>(url: string, options?: AxiosRequestConfig): Promise<Response<D>> => {
    options = {
        ...options,
        baseURL: apiConfig.uri,
        method: 'POST'
    }
    options = await addAuthorizationHeader<D>(options);
    return await applyRequest(url, options);
};
    
export const putRequest = async <D>(url: string, options?: AxiosRequestConfig): Promise<Response<D>> => {
    options = {
        ...options,
        baseURL: apiConfig.uri,
        method: 'PUT'
    }
    options = await addAuthorizationHeader<D>(options);
    return await applyRequest(url, options);
};
    
export const deleteRequest = async <D>(url: string, options?: AxiosRequestConfig) : Promise<Response<D>> => {
    options = {
        ...options,
        baseURL: apiConfig.uri,
        method: 'DELETE'
    }
    options = await addAuthorizationHeader<D>(options);
    return await applyRequest(url, options);
}

export const addAuthorizationHeader = async <D>(options: AxiosRequestConfig): Promise<AxiosRequestConfig<D>> => { 
    const bearer = `Bearer ${(await getSessionToken())?.accessToken}`; 
    let headers = {'Authorization': bearer};
    
    if (options.headers) {
        headers = {
            ...options.headers,
            ...headers
        }
    }
    
    return {
        ...options,
        headers 
    };
}

export const setAccount = async () => {
    const accounts = msalInstance.getAllAccounts();
    
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
        return await getSessionToken();
    } else {
        throw 'No exist loginned user';
    }
}

export const getSessionToken = async (): Promise<AuthenticationResult | undefined> => {
    try {
        const account = msalInstance.getActiveAccount();
        
        if (!account) {
            return await setAccount();
        } else {
            return await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            });
        }
    } catch (err) {
        msalInstance.loginRedirect();
    }
}


export const applyRequest = async <D>(url: string, options: AxiosRequestConfig): Promise<Response<D>> => {   
    
    return axios(url, options)
        .then(data => ({
            data: data.data,
            status: data.status,
            statusText: data.statusText,
        })).catch(err => {
            const axiosError = (err as AxiosError);
            switch(axiosError.code) {
                case 'ERR_NETWORK':
                    showErrorAlert({
                        title: 'Error',
                        children: 'Cannot connect to server.\nPlease check your internet connection',
                        isAlert: true
                    });
                    break;
                case 'ERR_BAD_RESPONSE':
                    showErrorAlert({
                        title: 'Error',
                        children: `Server communication error.\n${err.response.data.message}`,
                        isAlert: true
                    });
                    break;
                default: 
                    showErrorAlert({
                        title: 'Error',
                        children: 'Server communication error.',
                        isAlert: true
                    });
                    break;
            }
            throw(err);
        })
}