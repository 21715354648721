import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectSignificance, selectSoeEffects, toReadable } from '../../store/statesStore';
import { RootState } from '../../store/store';
import styles from './SoeContainer.module.scss';
import objectHash from 'object-hash';

const SoeContainer: FC = () => {
    
    const significance = useSelector((state: RootState) => selectSignificance(state));

    const soeEffects = useSelector((state: RootState) => selectSoeEffects(state));
    
    return (
        <>
            {
                significance.length > 0 &&
                <div className={styles.soeContainer}>
                    <div className={styles.title}>Interaction effects</div>
                    <div className={styles.tableWrapper}>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <th></th>
                                    {
                                        soeEffects.map(item => (
                                            <th key={item.name}>{ item.name }</th>
                                        ))
                                    }
                                </tr>
                                {
                                    soeEffects.map((item, itemIndex) => (
                                        <tr key={item.name}>
                                            <td>{ item.name }</td>
                                            {
                                                item.soe?.map((soeItem: any, soeItemIndex: number) => (
                                                    <td key={objectHash(soeItem)}>{(itemIndex === soeItemIndex) ? (soeItem) ? toReadable(soeItem) : '' : toReadable(soeItem) }</td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div> 
            }
        </>
    );
}

export default SoeContainer;