export const fileExtensionToMIMEType = {
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'xlsm': 'application/vnd.ms-excel.sheet.macroEnabled.12',
    'xlsb': 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'xls': 'application/vnd.ms-excel',
    'csv': 'text/csv'
};

export const exelFileExtensionList = [
    /*'xlsx',
    'xlsm',
    'xlsb',
    'xls'*/
    'csv'
];

export const imageFileExtensionList = [
    'svg',
    'jpeg',
    'png',
    'gif'
];

export function checkFileExtension(file: File, fileExtension: string[] | string): boolean { 
    if (Array.isArray(fileExtension)) {
        return fileExtension.some(fileExtensionItem => checkFileExtensionItem(file, fileExtensionItem))
    }
    return checkFileExtensionItem(file, fileExtension);
};


function checkFileExtensionItem (file: File, fileExtension: string): boolean {
    return file.type === (fileExtensionToMIMEType as any)[fileExtension];
}
    