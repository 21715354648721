import React, { FC } from 'react';
import styles from './Authentication.module.scss';

interface AuthenticationProps {}

const Authentication: FC<AuthenticationProps> = (props) => (
  <div className={styles.body}>
    <div className={styles.background}>
      <div className={styles.white}></div>
      <div className={styles.blue}></div>
      <div className={styles.image}></div>
    </div>
    <div className= {styles.mainWrapper}>
      <div className={styles.main}>
        <div className={styles.form}>
          <div className={styles.icon}>
            <img src="https://simdecstorage.blob.core.windows.net/login-page/logo.svg"/>
          </div>
          {
             props.children
          }
          
          <div className={styles.contact}>
            <img src="https://simdecstorage.blob.core.windows.net/login-page/email.icon.svg"/>
              info@simdec.fi
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.combines}>
            Combines
            <img src="https://simdecstorage.blob.core.windows.net/login-page/arrow.icon.svg"/>
          </div>
          <div className={styles.scenario}>
            Scenario
          </div>
          <div className={styles.infoFooter}>
            <div>
              Sensitivity & Uncertainty analysis
            </div>
            <div>
              Reveals synergies
            </div>
            <div>
              Provides actionable
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Authentication;
