import {FC} from 'react';
import styles from './LinkItem.module.scss';
import {NavLink} from "react-router-dom";

export interface LinkItemProps {
    iconPath: string,
    href: string,
    showDescription?: boolean,
    disabled?: boolean,
    title?: string
}

const LinkItem: FC<LinkItemProps> = (props) => {

    return (
        <NavLink 
            title={props.title ? props.title : ""} 
            className={`${styles.link} ${styles.linkNotActive}`} 
            activeClassName={styles.linkActive} 
            to={props.href}>
            <img src={props.iconPath}/>
            { props.showDescription && <span className={styles.linkName}>{props.children}</span>}
            
        </NavLink>
    );
}

export default LinkItem;
