import {Switch, Route, useHistory, Redirect} from "react-router-dom";
// Material-UI imports
import { ThemeProvider } from '@mui/material/styles';
import Grid from "@mui/material/Grid";

// MSAL imports
import {
    MsalAuthenticationResult,
    MsalAuthenticationTemplate,
    MsalProvider
} from "@azure/msal-react";
import { CustomNavigationClient } from "./libs/utils/NavigationClient";

// Sample app imports
import { Home } from "./pages/Home/Home";
import {theme} from "./styles/theme";
import Typography from "@mui/material/Typography";
import './styles/style.scss'
import { PageLayout } from "./pages/PageLayout/PageLayout";
import {loginRequest} from "./authConfig";
import Analysis from "./pages/Analysis/Analysis";
import Results from "./pages/Results/Results";
import AccountLayout from "./account/AccountLayout/AccountLayout";
import AlertLayout from "./account/components/alerts/AlertLayout/AlertLayout";
import Authentication from "./components/Authentication/Authentication";
import { Link } from "@mui/material";
import { msalConfig } from "./authConfig";
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, InteractionType } from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication(msalConfig);

function App() {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    msalInstance.setNavigationClient(navigationClient);
    const authRequest = {
        ...loginRequest
    };

    return (
      <ThemeProvider theme={theme}>
          <MsalProvider instance={msalInstance}>
              <MsalAuthenticationTemplate
                  interactionType={InteractionType.Redirect}
                  authenticationRequest={authRequest}
                  errorComponent={ErrorComp}
                  loadingComponent={LoadingComp}
              >
              <Typography>
                <AlertLayout></AlertLayout>
                <PageLayout>
                  <Grid container justifyContent="center">
                    <Pages />
                  </Grid>
                </PageLayout>
              </Typography>
              </MsalAuthenticationTemplate>
          </MsalProvider>
      </ThemeProvider>
    );
}

function Pages() {
    return (
        <Switch>
            <Route path="/analysis/:sessionId" render={props => <Results {...props}/>} />
            <Route path="/analysis">
                <Analysis />
            </Route>
            <Route path="/account">
                <AccountLayout/>
            </Route>
            <Route path="/home">
                <Home />
            </Route>
            <Redirect to="/home"/>
        </Switch>
    )
}

const LoadingComp = () => {
    return (
        <Authentication>
            <Typography variant="h6">Authentication in progress...</Typography>
        </Authentication>
    )
}

const ErrorComp: React.FC<MsalAuthenticationResult> = ({error}) => {
    return (
        <Authentication>
            <Typography variant="h6">An Error Occurred: {error ? error.errorMessage : "unknown error"}</Typography>
            <Link href="/home">To home </Link>
        </Authentication>
    )
}

export default App;
