import { Box } from "@mui/material";
import React, { FC, useRef } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { boxPlotOptions, boxPlotSeries } from "../../store/statesStore";
import { RootState } from "../../store/store";

const BoxPlotContainer: FC = () => {
    const chart = useRef(null);

    const chageColor = (stateColors: Array<string>) => {    
        if (chart.current) {
            stateColors.map((color, index) => {
                const pathFirst = ((chart.current as any).chartRef.current as HTMLElement).querySelector(`svg path:nth-child(${index+index+1})`);
                if (pathFirst) {
                    (pathFirst as HTMLElement).style.fill = color;
                }
                const pathSecond = ((chart.current as any).chartRef.current as HTMLElement).querySelector(`svg path:nth-child(${index+index+2})`);
                if (pathSecond) {
                    (pathSecond as HTMLElement).style.fill = color;
                }
                const paths = ((chart.current as any).chartRef.current as HTMLElement).querySelectorAll(`svg path`);
                paths.forEach(item => {
                    (item as HTMLElement).style.strokeWidth = '1';
                    (item as HTMLElement).style.stroke = '#000000';
                })
            });
        }
    }

    const boxPlotSeriesData = useSelector((state: RootState) => boxPlotSeries(state));
    const boxPlotOptionsData = useSelector((state: RootState) => boxPlotOptions(state));
    (boxPlotOptionsData.chart as any).events = {
        updated: function (chartContext: any, config: any) {
            chageColor(config.config.colors)
        },
        mounted: function (chartContext: any, config: any) {
            chageColor(config.config.colors)
        }
    };

    boxPlotOptionsData.colors = useSelector((state: RootState) => state.chartStore.colors);
    
    window.dispatchEvent(new Event('resize'))
    return (
        <Box id="boxPlotContainer">
            <Chart ref={chart} type="boxPlot" options={(boxPlotOptionsData as any)} series={boxPlotSeriesData}  />
        </Box>
    );
}

export default BoxPlotContainer;
